/**
 * Form validation utilities
 */

export const MIN_PASSWORD_LENGTH: number = 8;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email: string, isRequired: boolean = false): string => {
  if (isRequired && !email) {
    return Errors.EmailRequired;
  }

  if (isRequired && !EMAIL_REGEX.test(email)) {
    return Errors.EmailInvalid;
  }

  return '';
};

export const validatePwLength = (password: string, isRequired: boolean = false): string => {
  if (isRequired && !password) {
    return Errors.PasswordInvalid;
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    return Errors.PasswordLengthInvalid;
  }

  return '';
};

export function validatePassword(password: string, confirmPassword: string): string {
  if (password !== confirmPassword) {
    return Errors.PasswordMatchInvalid;
  }
  return '';
}

export enum Errors {
  GeneralError = 'There was an error on our end. Please try again shortly.',
  PasswordInvalid = 'Please enter a valid password.',
  PasswordMatchInvalid = 'Passwords do not match. Please try again.',
  PasswordLengthInvalid = 'Passwords must be at least 8 characters and cannot contain spaces.',
  EmailRequired = 'Email is a required field.',
  EmailInvalid = 'Please enter a valid email.',
}
