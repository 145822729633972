import uuidv4 from 'uuid/v4';

export function createCookie(name: string, value: string, days: number): string {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  const domain = window.location.hostname;
  document.cookie = `${name}=${value}${expires}; domain=.${domain}; path=/`;
  return value;
}

export function readCookie(name: string): null | string {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function generateUniqueId(): string {
  const uniqueId = uuidv4();
  return uniqueId;
}
