import React from 'react';
import styled from '@emotion/styled';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  outlined?: boolean;
};

const PillButton = styled.button<Props>`
  //spacing
  padding: ${props => props.theme.space[2]}px ${props => props.theme.space[4]}px;

  // borders
  border-width: 1.5px;
  border-style: solid;
  border-color: ${props => props.theme.colors.black};
  border-radius: ${props => props.theme.radii[4]};

  // colors
  background-color: ${props => {
    if (props.disabled) return props.theme.colors.gray;
    else if (props.outlined) return 'transparent';
    return props.theme.colors.black;
  }};
  color: ${props => (props.outlined ? props.theme.colors.black : props.theme.colors.white)};
  opacity: ${props => (props.disabled ? '0.4' : '1')};

  // typography
  font-size: ${props => props.theme.fontSizes[2]};
  text-align: center;
`;

export default PillButton;
