import React, { useState } from 'react';
import { Spinner } from 'shared/components';
import ErrorMessage from './ErrorMessage';
import ScrollWatch from './ScrollWatch';
import LoadMoreButton from './LoadMoreButton';

interface Props {
  hasNext: boolean;
  isFetching: boolean;
  hasError: boolean;
  resetInitialLoad?: boolean;
  container?: HTMLDivElement | null;
  handleClick: () => void;
}

function LoadMore({
  hasNext,
  isFetching,
  hasError,
  resetInitialLoad = false,
  container = null,
  handleClick,
}: Props) {
  const [initialLoad, setInitialLoad] = useState(true);

  function handleLoadMore() {
    setInitialLoad(false);

    if (hasNext && !isFetching) {
      handleClick();
    }
  }

  // Determine whether it's okay to load more or not
  // hasNext will come from response/reducer
  // isFetching is to avoid multiple requests being made
  const isFetchable = hasNext && !isFetching;

  const loadingComponent =
    initialLoad || resetInitialLoad ? (
      <LoadMoreButton handleClick={handleLoadMore} />
    ) : (
      <ScrollWatch onEnterViewport={handleLoadMore} container={container} />
    );

  const loadMoreComponent = isFetchable ? loadingComponent : null;

  // fetching - show loading indicator
  // error message and is not fetching - show error message
  // no error message and is not fetching - show loadMoreComponent
  return (
    <div>
      {isFetching && <Spinner style={{ height: 61, marginBottom: 80 }} />}

      {hasError && !isFetching && (
        <div>
          <ErrorMessage error="Error Loading Content" align="center" />

          {isFetchable && <LoadMoreButton handleClick={handleLoadMore} />}
        </div>
      )}

      {!hasError && !isFetching && loadMoreComponent}
    </div>
  );
}

export default LoadMore;
