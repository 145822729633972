/**
 * White space utilities
 */

import { css } from '@emotion/react';

// TODO: copy aurora's util-space.css file contents here and update all references
export default css`
  .m0 {
    margin: 0;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .ml20 {
    margin-left: 20px;
  }
  .p10 {
    padding: 10px;
  }
  .pt10 {
    padding-top: 10px;
  }
  .pb10 {
    padding-bottom: 10px;
  }
  .pr10 {
    padding-right: 10px;
  }
  .pl10 {
    padding-left: 10px;
  }
  .pt20 {
    padding-top: 20px;
  }
  .pb20 {
    padding-bottom: 20px;
  }
  .p30 {
    padding: 30px;
  }
  .pt30 {
    padding-top: 30px;
  }
  .pb30 {
    padding-bottom: 30px;
  }
  .pr30 {
    padding-right: 30px;
  }
  .pl30 {
    padding-left: 30px;
  }
`;
