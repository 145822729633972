export enum PageContext {
  UNKNOWN,
  COLLECTION,
  FEED,
  JOURNAL,
  PERSONAL_MEDIA,
  SEARCH,
  // NOTE: the EXTERNAL key is used to indicate that a user entered the
  // detail view screen from a location outside of our app
  EXTERNAL,
  FOLLOWING,
}
