import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from 'shared/utils/colors';
import { fonts } from 'shared/utils/fonts';

const BTN_HEIGHT = 48;

export const Container = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

interface ShouldHideProp {
  shouldHide: boolean;
}

export const MiniControlsContainer = styled('div')<ShouldHideProp>`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  opacity: ${(props: ShouldHideProp) => (props.shouldHide ? 0 : 1)};
  transition: opacity 0.5s;
  pointer-events: ${(props: ShouldHideProp) => (props.shouldHide ? 'none' : 'auto')};

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 48px;
    background: linear-gradient(transparent, ${colors.black} 65%);
    opacity: 0.2;
  }
`;

export const miniButtonBase = css`
  height: ${BTN_HEIGHT}px;
  width: ${BTN_HEIGHT}px;
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  /* removes the highlight that is triggered on tap */
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  svg {
    z-index: 1;
  }
`;

export const MuteUnmuteButton = styled('button')`
  ${miniButtonBase};
`;

export const ProgressBarPlaceholder = styled('div')`
  height: ${BTN_HEIGHT}px;
  width: 100%;
`;

export const TimeRemaining = styled('span')`
  display: inline-block;
  color: ${colors.white};
  font-size: 14px;
  ${fonts.medium};
  line-height: 1;
  z-index: 1;
  margin-top: 4px;
`;

export const ExpandButton = styled('button')`
  ${miniButtonBase};
  margin-left: -3px;
`;
interface TimeRemainingExpandContainerProps {
  hasExpand: boolean;
}

export const DurationExpandContainer = styled('div')<TimeRemainingExpandContainerProps>`
  display: flex;
  align-items: center;
  flex: none;
  height: ${BTN_HEIGHT}px;
  ${TimeRemaining} {
    margin-right: ${(props: TimeRemainingExpandContainerProps) => (props.hasExpand ? 0 : '16px')};
  }
`;

export const Spacer = styled('span')`
  display: inline-block;
  height: ${BTN_HEIGHT};
  width: ${BTN_HEIGHT};
  flex: none;
`;
