import styled from '@emotion/styled';
import { colors, fonts } from '../utils';

interface Props {
  type?: 'submit' | 'reset' | 'button';
  children: React.ReactNode | string;
  bgColor?: string;
  // color controls the text color of this component. This style will not be
  // used if either `disabled` or `outlined` are enabled.
  color?: string;
  outlined?: boolean;
  disabled?: boolean;
  isFullWidth?: boolean;
  onClick?: () => void;
}

const SxButton = styled('button')<Props>`
  --height: 48px;
  width: ${props => (props.isFullWidth ? '100%' : null)};
  height: var(--height);
  display: inline-block;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${props => {
    if (props.disabled) return colors.gray;
    else if (props.outlined) return 'transparent';
    return props.bgColor || colors.black;
  }};
  border-width: ${props => (props.outlined && !props.disabled ? '1.5px' : '0')};
  border-style: solid;
  border-color: ${props => props.bgColor || colors.black};
  border-radius: 2px;
  opacity: ${props => (props.disabled ? '0.4' : '1')};
  color: ${props =>
    props.outlined && !props.disabled
      ? props.bgColor || colors.black
      : props.color || colors.white};
  ${fonts.medium};
  font-size: 16px;
  line-height: var(--height);
  text-decoration: none;
`;

SxButton.defaultProps = {
  type: 'button',
};

export default SxButton;
