import React from 'react';
import { colors } from 'shared/utils';

interface Props {
  width?: number;
  height?: number;
}

function IconVideoControlsMute({ width = 20, height = 22 }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 24"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
    >
      <g fill={colors.white} fillRule="evenodd">
        <path fill="none" d="M-1 0h24v24H-1z" />
        <path d="M0 8h2v8H0zM20.121 8.464l1.415 1.415-5.657 5.657-1.415-1.415z" />
        <path d="M15.879 8.464L14.464 9.88l5.657 5.657 1.415-1.415zM5 4h2v16H5zM10 0h2v24h-2z" />
      </g>
    </svg>
  );
}

export default IconVideoControlsMute;
