import React, { ChangeEvent, ChangeEventHandler } from 'react';
import styled from '@emotion/styled';
import { CheckIcon } from './icons';

// Hide checkbox visually but remain accessible to screen readers.
// https://css-tricks.com/inclusively-hidden/
const CheckboxInputSd = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckmarkSd = styled('span')<{ disabled?: boolean; checked?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  padding: 0 ${props => props.theme.space[1]}px;
  border: ${props => props.theme.borders[1]};
  border-color: ${props => props.theme.colors.gray55};
  border-radius: ${props => props.theme.radii[1]};
  transition: background-color 0.3s ease-out;

  ${CheckboxInputSd}:checked + & {
    border-color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.black};

    svg {
      visibility: visible;
    }
  }

  ${CheckboxInputSd}:focus + & {
    box-shadow: ${props => `0 0 0 2px ${props.theme.colors.gray15}`};
  }

  svg {
    visibility: hidden;
  }
`;

const LabelSd = styled('label')<{ disabled: boolean }>`
  position: relative;
  user-select: none;
  cursor: pointer;
  ${props => props.disabled && `cursor: not-allowed;`}
`;

type Props = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value?: string;
  checked?: boolean;
};

function Checkbox({ checked, value, disabled = false, onChange }: Props) {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = e => {
    onChange?.(e);
  };

  return (
    <LabelSd disabled={disabled}>
      <CheckboxInputSd
        checked={checked}
        value={value}
        name={value}
        type="checkbox"
        onChange={handleOnChange}
      />
      <CheckmarkSd disabled={disabled}>
        <CheckIcon size={16} fill="white" />
      </CheckmarkSd>
    </LabelSd>
  );
}

export default Checkbox;
