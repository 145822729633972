import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Site } from '../types';

type Props = {
  title?: string;
  description?: string;
  lang?: string;
  meta?: object[];
  image?: string;
};

function SEO({ title = '', description = '', lang = 'en', meta = [], image = '' }: Props) {
  const { site }: { site: Site } = useStaticQuery(siteQuery);

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaImage = image ? `${site.siteMetadata.siteUrl}${image}` : site.siteMetadata.siteImg;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={title ? `%s | ${site.siteMetadata.title}` : ''}
      meta={[
        {
          name: 'google-site-verification',
          content: 'XW5TRB2Kwz0UqtusjD7hHD0f523UH6u1YQ78t25fXv8',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          name: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:site',
          content: '@vsco',
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:image',
          content: metaImage,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        ...meta,
      ]}
    />
  );
}

export default SEO;

const siteQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        siteImg
      }
    }
  }
`;
