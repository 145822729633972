import React from 'react';
import { css } from '@emotion/react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { kebabCase } from 'lodash';
import { Box, Grid } from 'shared/components';
import { breakPoints, colors, mediaQuery } from 'shared/utils';
import { GridIcon, ListIcon, AsteriskIcon } from 'shared/components/icons';

type Props = {
  location: Location;
};

function BlogNav({ location }: Props) {
  // TODO: implmenet grid/list switch state using useLocalStorage hook
  const isIndexOrTagsPage = location.pathname === '/' || location.pathname.includes('/tags');
  return (
    <Box>
      <Grid
        gridTemplateColumns={isIndexOrTagsPage ? [null, '1fr 108px'] : '1fr 108px'}
        gridGap={4}
        justifyContent="space-between"
      >
        {isIndexOrTagsPage ? <ListNav location={location} /> : <PostNav />}
      </Grid>
    </Box>
  );
}

export default BlogNav;

/**
 * GraphQL queries
 */

export const tagsQuery = graphql`
  query GetTags {
    tags: allMarkdownRemark(filter: { published: { eq: true } }, limit: 20) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

/**
 * Render Helpers
 */

function ListNav({ location }: Props) {
  const { tags } = useStaticQuery(tagsQuery);
  return (
    <>
      <Box display="flex" flexWrap="wrap">
        <Link
          to="/"
          css={sx.pill}
          activeStyle={{
            backgroundColor: colors.black,
            borderColor: colors.black,
          }}
        >
          <AsteriskIcon size={20} color={location.pathname === '/' ? colors.white : colors.black} />
        </Link>

        {tags.group.map(({ fieldValue: tag }: { fieldValue: string }) => (
          <Box key={tag}>
            <Link
              to={`/tags/${kebabCase(tag.toLowerCase())}`}
              css={[sx.pill, sx.textPill, { textTransform: 'capitalize' }]}
              activeStyle={{
                backgroundColor: colors.black,
                borderColor: colors.black,
                color: colors.white,
              }}
            >
              {tag.trim()}
            </Link>
          </Box>
        ))}
      </Box>

      {/* TODO: temporary `disable: none` once grid/list switch is built out */}
      <Box display={['none']}>
        <button css={sx.pill}>
          <GridIcon size={20} />
        </button>
        <button css={[sx.pill, { marginLeft: 8 }]}>
          <ListIcon size={20} />
        </button>
      </Box>
    </>
  );
}

function PostNav() {
  return (
    <>
      <Box display="flex">
        <Link to="/" css={sx.pill} title="Back" style={{ fontSize: 24 }}>
          &larr;
        </Link>
      </Box>

      <Box justifySelf="flex-end">
        <button
          css={[sx.pill, sx.textPill]}
          onClick={() => {
            document?.querySelector('#share-post')?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }}
        >
          Share
        </button>
      </Box>
    </>
  );
}

/**
 * Styles
 */

const sx = {
  pill: css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    margin-top: 8px;
    border-radius: 50%;
    border: 1px solid ${colors.gray};
    outline: none;

    &:hover,
    &:focus {
      background: ${colors.eggWhite};
    }

    div:last-child > & {
      margin-right: 0;
    }

    ${mediaQuery(breakPoints.MEDIUM)} {
      width: 50px;
      height: 50px;
    }
  `,
  textPill: css`
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 25px;

    ${mediaQuery(breakPoints.MEDIUM)} {
      width: auto;
    }
  `,
};
