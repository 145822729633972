import React from 'react';
import { colors } from 'shared/utils';

type Props = {
  width?: number;
  height?: number;
  color?: string;
  direction: 'up' | 'down' | 'left' | 'right';
};

function ArrowIcon({ width = 24, height = 24, color = colors.black, direction }: Props) {
  let path;

  switch (direction) {
    case 'up':
      path =
        'M18.6217 16.6199L12 9.82869L5.37834 16.6199L4 15.2061L12 7L20 15.2061L18.6217 16.6199Z';
      break;
    case 'down':
      path =
        'M18.6217 7.00005L12 13.7913L5.37834 7.00005L4 8.4139L12 16.62L20 8.4139L18.6217 7.00005Z';
      break;
    case 'left':
      path =
        'M10.2928 0.292908L11.707 1.70712L3.41491 10L11.707 18.2929L10.2928 19.7071L0.585693 10L10.2928 0.292908Z';
      break;
    case 'right':
      path =
        'M1.99992 0.292908L0.585711 1.70712L8.87782 10L0.585711 18.2929L1.99992 19.7071L11.707 10L1.99992 0.292908Z';
      break;
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d={path} fill={color} />
    </svg>
  );
}

export default ArrowIcon;
