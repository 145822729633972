import React, { useEffect, useRef, useState, useCallback } from 'react';
import { isElementInViewport, isTouchDevice, isSafari } from 'shared/utils/device';
import { getHlsSDK, getMuxSDK, getMuxEnvKey, isSrcHls, Mux } from 'shared/utils/video';
import { trackEvent } from 'shared/utils/analytics';
import { VIDEO_PLAYBACK_INTERACTION } from 'shared/constants/events';
import { VideoStatus } from 'shared/types';
import VideoControls, { VideoControlsEvent } from './VideoControls';
import VideoError from './VideoError';
import { PageContext } from 'shared/utils/page-context';
// NOTE: coming from @types/hls.js and not the real pkg.
import Hls from 'hls.js';

enum ErrorMessage {
  NETWORK_OR_MEDIA = `Couldn't load video`,
  DELETED = `This video's been deleted`,
}

// https://github.com/vsco/godel/blob/master/protobufs/protos/events/event_messaging.proto#L4654
enum InteractionType {
  UNKNOWN,
  PLAYED,
  PAUSED,
  SOUND_ON,
  SOUND_OFF,
  SEEK,
}

const eventToInteractionType: { [index: string]: InteractionType } = {
  play: InteractionType.PLAYED,
  pause: InteractionType.PAUSED,
  sound_on: InteractionType.SOUND_ON,
  sound_off: InteractionType.SOUND_OFF,
};

// This enum represents the various modes that the VideoControls component can operate in.
// Each mode reflects what controls are visible to the user:
// - CRT:
//   - play/pause
//   - mute/unmute
//   - duration
//   - progress/seek
// - Detail:
//   - same as above except:
//     - hide progress/seek
// - Default:
//   - same as CRT except:
//     - hide progress/seek
//     - show expand
export enum VideoControlsMode {
  INTERNAL,
  Detail,
  Default,
}

interface Props {
  id?: string;
  src: string;
  width: number;
  height: number;
  poster: string;
  duration?: number;
  hasAudio?: boolean;
  status?: number;
  siteId?: number;
  userId?: number;
  pageContext?: PageContext;
  controlsMode?: VideoControlsMode;
  style?: { [key: string]: string | number };
  onExpand?: () => void;
}

function Video({
  id,
  src,
  width,
  height,
  poster,
  duration,
  hasAudio = false,
  status = VideoStatus.CREATED,
  siteId = 0,
  userId = 0,
  pageContext,
  controlsMode = VideoControlsMode.Default,
  style = {},
  onExpand,
}: Props) {
  const playerRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);
  const [shouldShowControls, setShouldShowControls] = useState(isTouchDevice());
  const [errorMessage, setErrorMessage] = useState('');

  function trackInteractionEvent(evt: Event | VideoControlsEvent) {
    const type = eventToInteractionType[evt.type] || InteractionType.UNKNOWN;

    trackEvent(VIDEO_PLAYBACK_INTERACTION, {
      source: getPageType(pageContext),
      type,
    });
  }

  // NOTE: disables tracking of interaction events when used within an internal tool
  const isInternalApp = controlsMode === VideoControlsMode.INTERNAL;
  const handleInteractionEvents = useCallback(isInternalApp ? () => {} : trackInteractionEvent, [
    pageContext,
  ]);

  useEffect(() => {
    let player = playerRef.current;
    let mux: Mux;

    async function setupHls(player: HTMLVideoElement) {
      let Hls: any;

      try {
        // TODO: tsc is throwing the following error when it hits the expression below:
        // - Type 'unknown' is not assignable to type 'Mux'
        // I'll update the getSDK util to fix when time permits
        mux = await getMuxSDK();
        Hls = await getHlsSDK();
      } catch (e) {
        // TODO: investigate whether this error is caught in error boundary
        throw new Error('error occurred while fetching sdk');
      }

      // NOTE: even though desktop safari supports the MediaSource extensions API, its
      // implementation is buggy and these bugs manifest as flashes between end and start
      // and some general skipping during playback, hence the reason we are skipping
      // desktop safari here: https://github.com/video-dev/hls.js/issues/9
      if (!isSafari() && Hls.isSupported()) {
        hlsRef.current = new Hls();

        // type guard necessary since hlsRef.current is declared Hls | null
        if (hlsRef.current) {
          addHlsErrorHandlers(hlsRef.current, Hls);
          hlsRef.current.attachMedia(player);
        }
      } else if (player.canPlayType('application/vnd.apple.mpegurl')) {
        player.src = src;
      }

      // Initialize Mux Data SDK
      initMuxData(mux, player, hlsRef.current);

      setShouldShowControls(true);
    }

    // not married to this name
    // Attempt to autoplay video on mount. If play promise fails, this indicates that autoplay is not allowed
    async function maybeAutoPlay(player: HTMLVideoElement) {
      if (isElementInViewport(player)) {
        if (isTouchDevice()) {
          setShouldShowControls(true);
        } else {
          try {
            await player.play();
          } catch (err) {
            if (err.name === 'NotAllowedError') {
              setShouldShowControls(true);
            }
          }
        }
      }
    }

    function initMuxData(mux: Mux, player: HTMLVideoElement, hls: Hls | null) {
      mux.monitor(player, {
        debug: false,
        hlsjs: hls,
        data: {
          env_key: getMuxEnvKey(),
          page_type: getPageType(pageContext),
          player_init_time: Date.now(),
          video_id: id,
          video_title: id,
          video_producer: siteId,
          viewer_user_id: userId,
        },
      });
    }

    if (isSrcHls(src) && player instanceof HTMLVideoElement) {
      setupHls(player);
    } else if (player instanceof HTMLVideoElement) {
      maybeAutoPlay(player);
    }

    function handleError() {
      setErrorMessage(ErrorMessage.NETWORK_OR_MEDIA);
    }

    player?.addEventListener('error', handleError);
    player?.addEventListener('play', handleInteractionEvents);
    player?.addEventListener('pause', handleInteractionEvents);

    return () => {
      player?.pause();
      player?.removeEventListener('error', handleError);
      player?.removeEventListener('play', handleInteractionEvents);
      player?.removeEventListener('pause', handleInteractionEvents);
      hlsRef.current?.destroy();
      if (mux && player instanceof HTMLVideoElement) mux.destroyMonitor(player);
    };
  }, [src, id, siteId, userId, pageContext, handleInteractionEvents]);

  function addHlsErrorHandlers(hls: Hls, Hls: any) {
    hls.on(Hls.Events.ERROR, (_: string, data: any) => {
      if (data.fatal) {
        switch (data.type) {
          // NOTE: I've temporarily removed the fatal error recovery logic until I have a better understanding
          // of how it effects the Video component:
          // https://github.com/video-dev/hls.js/blob/master/docs/API.md#fatal-error-recovery
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.error('[Hls] fatal network error occurred');
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.error('[Hls] fatal media error occurred');
            break;
          default:
            console.error('[Hls] unrecoverable error occurred');
            break;
        }
        hls.destroy();
        setErrorMessage(ErrorMessage.NETWORK_OR_MEDIA);
      }
    });
  }

  function getSource(url: string) {
    if (isSrcHls(url)) return;

    return url;
  }

  function getPosterUrl(): string {
    return `${poster}?time=0&width=${width}`;
  }

  function getPlayer() {
    return playerRef.current;
  }

  function getHls() {
    return hlsRef.current;
  }

  if (errorMessage || status === VideoStatus.DELETED) {
    return <VideoError message={errorMessage || ErrorMessage.DELETED} />;
  } else {
    return (
      <>
        <video
          key={id}
          ref={playerRef}
          src={getSource(src)}
          preload="none"
          muted
          loop
          playsInline
          poster={getPosterUrl()}
          width={width}
          height={height}
          onContextMenu={e => e.preventDefault()}
          style={style}
          data-testid="video-player"
        >
          <img alt="Video not supported for your browser" src={getPosterUrl()} />
        </video>

        {shouldShowControls && (
          <VideoControls
            duration={duration}
            hasAudio={hasAudio}
            mode={controlsMode}
            src={src}
            mediaId={id}
            getPlayer={getPlayer}
            getHls={getHls}
            onExpand={onExpand}
            onSoundOn={handleInteractionEvents}
            onSoundOff={handleInteractionEvents}
          />
        )}
      </>
    );
  }
}

function getPageType(pageContext?: PageContext) {
  switch (pageContext) {
    case PageContext.FEED:
      return 'Feed';
    case PageContext.PERSONAL_MEDIA:
      return 'Profile';
    default:
      return 'Video Detail View';
  }
}

export default Video;
