import { css } from '@emotion/react';

export default css`
  /* Completely remove from the flow but leave available to screen readers. */
  .sr-only {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .w-100 {
    width: 100%;
  }
`;
