// TODO: break up this types file by resource
import { Logger } from 'log4js';
import { GrpcConfig } from './grpc/types';
import * as c from './constants';
import { VideoFromService } from './api/videoApi';
export { default as Api } from './api';

export type Settings = {
  protocol: 'http' | 'https';
  domain: string;
  host: string;
  risUrl: string;
  staticUrl: string;
};

export type EntitiesImage = {
  [mediaId: string]: Media;
};

export type EntitiesArticle = {
  [articleId: string]: Article;
};

export type EntitiesCollection = {
  [collectionId: string]: Collection;
};

export type EntitiesVideo = {
  [videoId: string]: Video;
};

export type EntitiesUser = {
  [userId: string]: User;
};

export type EntitiesSite = {
  [siteId: string]: Site;
};

export type EntitiesSpace = {
  [spaceId: string]: Space;
};

// Using the feed protos MediaType enum as reference
// https://github.com/vsco/godel/blob/master/protobufs/protos/feed/feed.proto#L17
export enum MediaType {
  IMAGE = 'image',
  COLLECTION = 'collection',
  ARTICLE = 'article',
  COLLECTION_ITEM = 'collection_item',
  VIDEO = 'video',
  DSCO = 'DSCO',
}

export type Media = {
  id: string;
  adaptiveBase: string;
  captureDate: number;
  captureDateMs: number;
  copyrightClasses: Array<string>;
  description: string;
  descriptionAnchored: string;
  featureLink: null;
  gridName: string;
  height: number;
  imageMeta: ImageMeta;
  imageStatus: ImageStatus;
  isFeatured: boolean;
  isVideo: boolean;
  permaDomain: string;
  permaSubdomain: string;
  permalink: string;
  preset: ImagePreset;
  responsiveUrl: string;
  shareLink: string;
  showLocation: number;
  siteId: number;
  tags: Array<string>;
  uploadDate: number;
  videoUrl: string;
  width: number;
  // mediaIndex & collectionId properties are used for navigation in detail view
  mediaIndex?: number;
  collectionId?: string;
};

interface ImageStatus {
  code: ImageSts;
  time: number;
}

export enum ImageSts {
  PREUPLOAD = 0,
  ACTIVE = 1,
  INACTIVE = 2,
  PENDING = 3,
  DELETED = 4,
  DMCA_PENDING = 5,
  DMCA_REMOVED = 6,
  FLAGGED_PENDING = 7,
  FLAGGED_REMOVED = 8,
  ACCOUNT_INACTIVE = 9,
  DELETE_COMPLETE = 10,
}

export type CollectionItem =
  | (CollectionItemBase & { media: CollectionItemMedia })
  | (CollectionItemBase & { video: CollectionItemVideo });

export type CollectionItemBase = {
  id: string;
  collectionId: string;
  collectorSiteId: number;
  createdDate: number;
  lastUpdated: number;
  reactions: {
    repost: {
      status: number;
      lastUpdated: number;
    };
  };
  uploaderSiteId: number;
};

export type CollectionItemVideo = VideoFromService;
export type CollectionItemMedia = {
  id: string;
  captureDate: number;
  description: string;
  env: string;
  fileName: string;
  gridName: string;
  height: number;
  imageMeta: ImageMeta;
  imageStatus: {
    code: number;
    time: number;
  };
  isVideo: boolean;
  location: {
    lat: number;
    lng: number;
  };
  permaSubdomain: string;
  responsiveUrl: string;
  shareLink: string;
  showLocation: boolean;
  site: HydratedSite;
  siteId: number;
  sites: Array<number>;
  source: string;
  status: number;
  storageLocation: string;
  thumbnailSize: CollectionItemsThumbnailSize[];
  updateDate: number;
  uploadDate: number;
  user: {
    id: number;
    name: string;
  };
  userId: number;
  width: number;
  videoUrl: string;
};

export type CollectionItemsThumbnailSize = {
  cropx: number;
  cropy: number;
  cropWidth: number;
  cropHeight: number;
  height: number;
  width: number;
};

export type ImageMeta = {
  aperture: number;
  copyright: string;
  editStack: {
    key: string;
  };
  flashMode: string;
  iso: number;
  model: string;
  shutterSpeed: string;
  whiteBalance: string;
};

export type ImagePreset = {
  color: string;
  key: string;
  shortName: string;
};

export type Article = {
  articleStatus: ArticleStatus;
  body: (ArticleContentText | ArticleContentImage | ArticleContentVideo)[];
  coverImage: string;
  coverImageMeta: CoverImageMeta;
  createdDate: number;
  id: string;
  lastUpdated: number;
  permalink: string;
  publishedDate: number;
  siteId: number;
  subtitle: string;
  title: string;
  author: string;
  domain: string;
  gridName: string;
};

interface ArticleStatus {
  code: ArticleSts;
  timestamp: number;
}

export enum ArticleSts {
  DRAFT = 0,
  PUBLISHED = 1,
  DELETED = 2,
}

export enum ArticleBodyContentType {
  HEADLINE = 'headline',
  IMAGE = 'image',
  TEXT = 'text',
  VIDEO = 'video',
}

export type ArticleContentText = {
  content: string;
  type: ArticleBodyContentType.HEADLINE | ArticleBodyContentType.TEXT;
};

export type ArticleContentImage = {
  content: ArticleImage[];
  type: ArticleBodyContentType.IMAGE;
};

export type ArticleContentVideo = {
  content: ArticleVideo;
  type: ArticleBodyContentType.VIDEO;
};

export type ArticleImage = {
  caption?: {
    content: string;
    type: ArticleBodyContentType.TEXT;
  };
  height: number;
  id: string;
  responsiveUrl: string;
  width: number;
};

export type ArticleVideo = {
  id: string;
  provider: string;
  resourceId: string;
  webUrl: string;
};

// https://github.com/vsco/godel/blame/master/protobufs/protos/media_meta/media_meta.proto#L15
export type CoverImageMeta = {
  height: number;
  width: number;
  responsiveUrl: string;
  videoUrl?: string;
  isVideo?: boolean;
};

// This is the JSON structure of the site proto without using the FetchSiteAPIResponse serializer
// https://github.com/vsco/godel/blob/840731b4eb467b8dd7fe3d5d9b847925e123785b/site/service/http/site.go#L196
export interface HydratedSite {
  id: string;
  siteId: number;
  domain: string;
  userId: number;
  addedDate: number;
  active: boolean;
  status: string;
  type: number;
  isBrand: boolean;
  name: string;
  description: string;
  externalLink: string;
  profileImage: {
    id: string;
    responsiveUrl: string;
  };
  albums: {
    grid: string;
    journal: string;
    profile: string;
  };
  siteCollectionId: string;
  gridAlbumId: string;
  groupId: number;
  profileImageUrl: string;
}

export type Site = {
  collectionShareLink: string;
  description: string;
  domain: string;
  externalLink: string;
  externalLinkDisplayText: string;
  gridAlbumId: string;
  hasArticle: boolean;
  hasCollection: boolean;
  hasGrid: boolean;
  id: number;
  isBrand: boolean;
  internalSite: boolean;
  museum: boolean;
  name: string;
  profileImage: string;
  profileImageId: string;
  profileImageUrl?: string;
  recentlyPublished: string;
  responsiveUrl: string;
  shareLink: string;
  siteCollectionId: string;
  status: string;
  subdomain: string;
  type: number;
  userId: number;
};

export type User = {
  userId: number;
  idStr: string;
  active: boolean;
  firstName: string;
  lastName: string;
  email: string;
  twitter: string;
  locale: string;
  emailValidated: boolean;
  createdAtMs: number;
};

export interface EnvConfig {
  adminTkn?: string;
  proxy?: string;
  showDebug: boolean;
  showHttpRequests: boolean;
  logLevel: string;
  rosco: GrpcConfig;
  experiment: GrpcConfig;
  cookie: CookieConfig;
  dcdr: {
    path: string;
  };
  vsco: Settings;
  authorization?: AuthorizationConfig;
}

export interface CookieConfig {
  domain: string;
  httpOnly: boolean;
  secure: boolean;
  sameSite: boolean;
  maxAge: number;
}

export interface AuthorizationConfig {
  admins: number[];
  leads: number[];
}

export type Config = EnvConfig & {
  log: (...args: string[]) => void;
  logger: Logger;
  env: string;
};

export type Assignment = {
  bucketName: string;
};

export type Assignments = {
  [experimentName: string]: Assignment;
};

export type SessionLang = {
  language: string;
  locale: string;
};

export type Session = {
  country: string;
  email: string;
  lang: SessionLang;
  sId: string;
  tkn: string;
  userId: number;
};

// https://github.com/vsco/godel/blob/master/protobufs/protos/video/video.proto
export type Video = {
  id: string;
  uploadId: string;
  siteId: number;
  userId: number;
  description: string;
  domain: string;
  createdDate: number;
  lastUpdated: number;
  status: VideoStatus;
  statusDate: number;
  deleteStatus: ProcessingStatus;
  deleteStatusDate: number;
  playbackUrl: string;
  posterUrl: string;
  width: number;
  height: number;
  durationSec: number;
  hasAudio: boolean;
  contentType: VideoContentType;
  // mediaIndex & collectionId properties are used for navigation in detail view
  mediaIndex?: number;
  collectionId?: string;
  site?: HydratedSite;
};

export enum VideoStatus {
  UNKNOWN,
  CREATED,
  DELETED,
}

export enum ProcessingStatus {
  UNKNOWN,
  NOT_STARTED,
  STARTED,
  DONE,
  ERROR,
}

export enum VideoContentType {
  UNKNOWN,
  VIDEO,
  MONTAGE,
  DSCO,
}

export type PinOptions = {
  overlay: Overlay;
  override: Override;
  tapAction: TapAction;
};

type Overlay = {
  asset: { [index: string]: string };
  file: string;
  frames: number;
  name: string;
  speed: number;
  type: string;
};

type Override = {
  gridName: string;
  gridTapAction: TapAction;
};

type TapAction = {
  url: string;
  urlFallback: string;
};

export type Collection = {
  id: string;
  siteId: number;
  coverImage: string;
  coverImageMeta: CoverImageMeta;
  description: string;
  status: number;
  createdDate: number;
  lastUpdated: number;
  domain: string;
  gridName: string;
  title: string;
  feedCopy: string;
};

export type Metrics = {
  increment: (stat: string) => void;
  createTimer: (timer: string) => Timer;
};

interface Timer {
  stop: () => void;
}

// https://github.com/vsco/godel/blob/master/protobufs/protos/report/report.proto
export type Report = {
  id: number;
  reason: c.Reason;
  reporterId: number;
  ownerId: number;
  ownerSiteId: number;
  ownerFirstName: string;
  ownerLastName: string;
  ownerEmail: string;
  adminId?: number;
  mediaId: string;
  mediaLocation?: string;
  mediaType: c.ReportMediaType;
  status: c.ReportStatus;
  escalation?: c.EscalationLevel;
  notes?: ReportNote[];
  actions: Action[];
  ncmecReportId?: string;
  site?: HydratedSite;

  // ML match info
  matchReasons?: SaferMatchReason[];
  nsfwScore?: number;
  saferCsamPrediction?: number;
  saferPornographyPrediction?: number;
  saferSources?: SaferSource[];

  createdDate?: number;
  updatedDate?: number;
  spaceId?: string;
  space?: Space;
  spacePost?: SpacePost;
  spacePostComment?: SpacePostComment;
};

export enum SaferMatchReason {
  UNKNOWN,
  MATCHED,
  CLASSIFIED,
}

export enum SaferSource {
  // DEPRECATED
  // From the Cybertip.ca shared hash set.
  S_CYPERTIP_CA = 0,

  // NCMEC Electronic Service Provided shared hashes from Facebook
  S_ESP_FACEBOOK = 1,

  // NCMEC Electronic Service Provided shared hashes from Google
  S_ESP_GOOGLE = 2,

  // NCMEC Electronic Service Provided shared hashes from Microsoft
  S_ESP_MICROSOFT = 3,

  // NCMEC Electronic Service Provided shared hashes from Snapchat
  S_ESP_SNAPCHAT = 4,

  // From the Internet Watch Foundation
  S_IWF = 5,

  // From the NCMEC NGO shared hash set
  S_NCMEC_NGO = 6,

  // Non-CSAM but exploitative content shared by NCMEC(opt-in)
  S_NCMEC_SE = 7,

  // From the Arachnid shared hash set
  S_ARACHNID = 8,
}
// Collapsed version of report that dedupes media ids.
export type DedupedReport = {
  id: number;
  mediaId: string;
  reason: c.Reason;
  createdDate?: number;
  hasStatusChanged?: boolean;
  mediaType: c.ReportMediaType;
};

export type ReportNote = {
  id?: number;
  mediaId: string;
  adminId: number;
  note: string;
  createdDate?: number;
  updatedDate?: number;
};

export type ReportMedia = {
  id: string;
  type: c.ReportMediaType;
  ownerId?: number;
  ownerSiteId?: number;
  // NOTE: isWithinArticle and articleContext properties are not defined in the
  // original ReportMedia proto and are only used within CRT
  isWithinArticle?: boolean;
  articleContext?: c.ArticleContext;
};

export enum ActionType {
  AT_UNKNOWN = 0,
  AT_IGNORE = 1,
  AT_DELETE = 2,
  AT_ESCALATE = 3,
  AT_DE_ESCALATE = 4,
  AT_SUSPEND_USER = 5,
  AT_SEND_TO_NCMEC = 6,
  AT_SUSPEND_SPACE = 7,
  AT_REMOVE_SPACE_CONTRIBUTOR = 8,
}

export type Action = {
  type: ActionType;
  contentId: string;
  contentType: c.ReportMediaType;
  reason: c.Reason;
  escalation: c.EscalationLevel;
  spaceId?: number;
  // NOTE: isWithinArticle and articleContext properties are not defined in the
  // original Action proto and are only used within CRT
  isWithinArticle?: boolean;
  articleContext?: c.ArticleContext;
};

export type PersonalMedia = Media | Video;

export interface SearchResultPeople {
  following: boolean;
  gridImage: string;
  gridImageId: string;
  gridName: string;
  responsiveUrl: string;
  siteDomain: string;
  siteId: number;
  siteSubDomain: string;
  siteType: number;
  userId: number;
  userName: string;
}

export interface SearchResultImage {
  imageId: string;
  description: string;
  dimensions: {
    height: number;
    width: number;
  };
  grid: {
    domain: string;
    name: string;
    siteId: number;
    subdomain: string;
  };
  location: Location | null;
  user: {
    id: number;
    name: string;
  };
  responsiveUrl: string;
  videoUrl: string;
  // mediaIndex property is used for navigation in detail view
  mediaIndex?: number;
}
export interface SearchResultArticle {
  author: string;
  article: {
    id: string;
  };
  coverImage: string;
  coverImageMeta: {
    height: number;
    responsiveUrl: string;
    width: number;
  };
  grid: {
    domain: string;
    name: string;
  };
  permalink: string;
  siteId: number;
  subtitle: string;
  title: string;
  meta: {
    id: string;
  };
}

// Space related types & enums
enum ActionSource {
  AS_UNKNOWN = 0,
  // user-initiated deletion
  AS_USER = 1,
  // deleted by an admin
  AS_ADMIN = 2,
}

enum ApprovalState {
  APP_UNKNOWN = 0,
  // user is approved
  APP_APPROVED = 1,
  // user is awaiting approval
  APP_PENDING = 2,
  // user's request to join has been rejected
  APP_REJECTED = 3,
}

export enum SpaceRoleId {
  ROLE_UNKNOWN = 0,
  ROLE_OWNER = 1,
  ROLE_MODERATOR = 2,
  ROLE_VIEWER = 5,
  ROLE_COLLABORATOR = 3,
  ROLE_ADMIN = 4, // This is a VSCO Admin.
}

export type ShareRoles = {
  roleId: SpaceRoleId;
  shareCode: string;
};

enum DeletionType {
  DT_NONE = 0,
  DT_DELETION = 1,
  DT_SUSPENSION = 2,
}

export type DeleteInfo = {
  deletionType: DeletionType;
  source: ActionSource;
  deletedTimestamp: number;
};

export type SpaceUser = {
  id: string;
  spaceId: string;
  userId: number;
  roleId: SpaceRoleId;
  approvalState: ApprovalState;
  createdTimestamp: number;
  userInfo: UserInfo;
  role: {
    id: SpaceRoleId;
    name: string;
  };
};

export type UserInfo = {
  firstName: string;
  lastName: string;
  domain: string;
  profileImage: {
    id: string;
    responsiveUrl: string;
  };
  siteId: number;
  siteTitle?: string;
};

export type SpacePost = {
  id: string;
  spaceId: string;
  userId: number;
  mediaId: string;
  mediaType: MediaType;
  caption: string;
  deleteInfo: DeleteInfo;
  createdTimestamp: number;
  updatedTimestamp: number;
  media: {
    image?: Media;
    video?: Video;
  };
  lastCommentTimestamp: number;
  commentCount?: number;
  userInfo: UserInfo;
};

// SpacePostComment is the representation of a comment on a SpacePost
export type SpacePostComment = {
  id: string;
  spacePostId: string;
  contents: string;
  authorUserId: number;
  deleteInfo: DeleteInfo;
  createdTimestamp: number;
  updatedTimestamp: number;
  edited: boolean;
  userInfo: UserInfo;
};

// https://github.com/vsco/godel/blob/master/protobufs/protos/spaces/space.proto
export type Space = {
  id: string;
  title: string;
  description: string;
  ownerUserId: number;
  coverPostId: string;
  shareRoles: ShareRoles[];
  deleteInfo: DeleteInfo;
  createdTimestamp: number;
  updatedTimestamp: number;
  lastPostTimestamp: number;
  userCount: number;
  coverImage: Media;
  spaceUsers: SpaceUser[];
  ownerUserInfo: UserInfo;
};

export type SpaceCursor = {
  lastContext: {
    postCursorContext: PostCursorContext;
    commentCursorContext: CommentCursorContext;
  };
  atEnd: boolean;
  limit: number;
};

export type PostCursorContext = {
  postId: string;
};

export type CommentCursorContext = {
  commentId: string;
};

export enum SpaceErrorCode {
  // ERR_NONE indicates there was no error. The operation was successful.
  ERR_NONE = 0,

  /*
   * Errors relevant to multiple endpoints
   */

  // Something unexpected went wrong on the server
  ERR_INTERNAL = 1,
  // Invalid arguments
  ERR_INVALID_ARGUMENTS = 2,
  // User not authenticated
  ERR_UNAUTHENTICATED = 20,
  // Requester does not have permission to take this action
  ERR_PERMISSION_DENIED = 21,
  // Item not found or item forbidden from being returned to the requester
  ERR_NOT_FOUND = 30,

  /*
   * Errors relevant to Space endpoints
   */

  // too many spaces created by this user
  ERR_TOO_MANY_SPACES = 201,
  // space name is not unique for this user
  ERR_DUPLICATE_SPACE_NAME = 202,
  ERR_INVALID_SPACE_NAME = 203,
  ERR_INVALID_SPACE_DESCRIPTION = 204,

  /*
   * Errors relevant to SpaceUser endpoints
   */
  ERR_EXCEEDS_COLLABORATOR_LIMIT = 300,
  /*
   * Errors relevant to SpacePost endpoints
   */

  /*
   * Errors relevant to SpacePostComment endpoints
   */
}

export type SpaceError = {
  errorCode: SpaceErrorCode;
};

export type SpaceResponseStatus = {
  error: SpaceError;
};
