import React from 'react';
import { css, keyframes } from '@emotion/react';
import { colors } from 'shared/utils';
import { centerAbsolute } from 'shared/utils/styles';

const DEFAULT_SPINNER_SIZE = 40;

interface Props {
  size?: number;
}

const rotate = keyframes`
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
`;

// TODO: add a wrapper element to provide contrast for light colored video content
const spinnerSx = css`
  display: block;
  position: absolute;
  ${centerAbsolute(DEFAULT_SPINNER_SIZE)};
  animation: ${rotate} 1s infinite linear;
`;

function VideoControlsSpinner({ size = DEFAULT_SPINNER_SIZE }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      css={spinnerSx}
    >
      <g fill={colors.white} fillRule="nonzero">
        <path d="M20 31.95c6.6 0 11.95-5.35 11.95-11.95S26.6 8.05 20 8.05 8.05 13.4 8.05 20 13.4 31.95 20 31.95zm0-1.9c-5.55 0-10.05-4.5-10.05-10.05 0-5.55 4.5-10.05 10.05-10.05 5.55 0 10.05 4.5 10.05 10.05 0 5.55-4.5 10.05-10.05 10.05z" />
        <path d="M19 30.5h2v-21h-2z" />
        <path d="M.05 20C.05 31.018 8.982 39.95 20 39.95c11.018 0 19.95-8.932 19.95-19.95C39.95 8.982 31.018.05 20 .05 8.982.05.05 8.982.05 20zm1.9 0c0-9.969 8.081-18.05 18.05-18.05 9.969 0 18.05 8.081 18.05 18.05 0 9.969-8.081 18.05-18.05 18.05-9.969 0-18.05-8.081-18.05-18.05z" />
      </g>
    </svg>
  );
}

export default VideoControlsSpinner;
