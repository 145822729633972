import * as React from 'react';
import styled from '@emotion/styled';
import { colors, fonts } from '../utils';
import { InputTip } from 'shared/components';

type Props = {
  children: React.ReactElement | string;
  htmlFor: string;
  isHidden?: boolean;
  isRequired?: boolean;
  isBold?: boolean;
  tip?: string;
};

const Label = ({
  children,
  htmlFor,
  isHidden = false,
  isRequired = false,
  isBold = false,
  tip = '',
}: Props) => {
  if (!children) return null;

  return (
    <div>
      {isHidden ? (
        <SxHiddenLabel htmlFor={htmlFor}>{children}</SxHiddenLabel>
      ) : (
        <SxLabel htmlFor={htmlFor} isBold={isBold}>
          {isRequired ? addRequired(children) : children}
        </SxLabel>
      )}

      {tip && <InputTip tip={tip} hide={isHidden} />}
    </div>
  );
};

export default Label;

const SxLabel = styled('label')<{ isBold: boolean }>`
  display: block;
  margin-bottom: 8px;
  color: ${colors.charcoal};
  font-size: 14px;
  text-align: left;
  ${props => (props.isBold ? fonts.medium : fonts.book)};
`;

const SxHiddenLabel = styled('label')`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`;

const SxRequiredSpan = styled('span')`
  color: ${colors.red};
`;

const addRequired = (children: React.ReactElement | string) => {
  return (
    <>
      {children}
      <SxRequiredSpan>*</SxRequiredSpan>
    </>
  );
};
