import React, { useRef } from 'react';
import { colors } from '../utils';

type Props = {
  width: number;
  height: number;
  children: React.ReactElement;
};

const BG_COLORS = [
  colors.grayDark,
  colors.wetConcrete,
  colors.grayLight,
  colors.offWhite,
  colors.charcoalLight,
  colors.gold,
];

const MediaBgColorWrapper = ({ width, height, children }: Props) => {
  const padding = 100 / (width / height);
  const loadingBgColor = useRef(BG_COLORS[Math.floor(Math.random() * BG_COLORS.length)]);

  const { sxWrapper, sxChild } = getStyles(padding, loadingBgColor.current);

  return (
    <div css={{ position: 'relative', ...sxWrapper }}>
      {React.cloneElement(children, { style: sxChild })}
    </div>
  );
};

const getStyles = (padding: number, bgColor: string) => {
  const sxWrapper = {
    display: 'block',
    width: '100%',
    height: 'auto',
    paddingTop: `${padding}%`,
    backgroundColor: bgColor,
    overflow: 'hidden',
    transition: 'background-color 0.2s',
  };

  const sxChild = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'translateZ(0)',
    willChange: 'transform, opacity',
  };
  return { sxWrapper, sxChild };
};

export default MediaBgColorWrapper;
