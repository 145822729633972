import React from 'react';
import { Box, Text } from 'shared/components';

function Footer() {
  return (
    <Box as="footer" display="flex" justifyContent="flex-end" m={6}>
      <Text as="small" color="gray" textAlign="right" fontSize={2}>
        © VSCO {new Date().getFullYear()}. All rights reserved.
      </Text>
    </Box>
  );
}

export default Footer;
