import React, { CSSProperties } from 'react';
import { colors } from 'shared/utils';

interface Props {
  size?: number;
  fill?: string;
  style?: CSSProperties;
}

const CheckIcon = ({ size = 24, fill = colors.black, style = {} }: Props) => (
  <svg
    width={size}
    height={size}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Check</title>
    <g fill="none" fillRule="evenodd">
      <polygon
        fill={fill}
        points="21.28 4 8.7 16.58 2.414 10.278 1 11.691 8.696 19.406 22.694 5.414"
      />
    </g>
  </svg>
);

export default CheckIcon;
