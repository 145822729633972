export enum KeyCode {
  ESC = 'Escape',
  LEFT_ARROW = 'ArrowLeft',
  RIGHT_ARROW = 'ArrowRight',
  D = 'KeyD',
  F = 'KeyF',
  I = 'KeyI',
  J = 'KeyJ',
  K = 'KeyK',
  M = 'KeyM',
  P = 'KeyP',
  U = 'KeyU',
  COMMA = 'Comma',
  PERIOD = 'Period',
  QUOTE = 'Quote',
  ENTER = 'Enter',
}
