import { useState, useEffect } from 'react';
import { throttle } from 'lodash';
import { isServer } from 'shared/utils';

type ScrollPosition = {
  x: number;
  y: number;
};

export function useScrollPosition() {
  const [position, setScrollPosition] = useState<ScrollPosition>(getScrollPosition());

  useEffect(() => {
    let requestRunning: number | null = null;
    const handleScroll = throttle(() => {
      if (!isServer() && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    }, 500);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return position;
}

function getScrollPosition(): ScrollPosition {
  if (isServer()) return { x: 0, y: 0 };
  return { x: window.pageXOffset, y: window.pageYOffset };
}
