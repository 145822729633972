import { SpaceRoleId } from 'shared/types';

export const SPACE_ROLE_TO_TEXT = {
  [SpaceRoleId.ROLE_ADMIN]: 'Admin',
  [SpaceRoleId.ROLE_COLLABORATOR]: 'Collaborator',
  [SpaceRoleId.ROLE_MODERATOR]: 'Moderator',
  [SpaceRoleId.ROLE_OWNER]: 'Owner',
  [SpaceRoleId.ROLE_UNKNOWN]: 'Unknown',
  [SpaceRoleId.ROLE_VIEWER]: 'Viewer',
};
