/**
 * Event constants
 */

export const APP_INSTALL_BANNER_EXITED = 'App Install Banner Exited';
export const APP_INSTALL_BANNER_TAPPED = 'App Install Banner Tapped';
export const CAMPAIGN_VIDEO_STARTED = 'Campaign Video Started';
export const CAMPAIGN_VIDEO_ENDED = 'Campaign Video Ended';
export const CONTENT_PIN_TAPPED = 'Content Pin Tapped';
export const CONTENT_REPORTED = 'Content Reported';
export const CONTENT_SEARCHED = 'Content Searched';
export const CONTENT_SHARED = 'Content Shared';
export const CONTENT_USER_FOLLOWED = 'Content User Followed';
export const DELETED_ACCOUNT = 'Deleted Account';
export const EXPERIMENT_ACTIVATED = 'Experiment Activated';
export const LOGIN_CLICKED = 'Login Clicked';
export const PAGE_VIEWED = 'Page Viewed';
export const REPORTED_MEDIA_DELETED = 'Reported Media Deleted';
export const REPORTED_MEDIA_IGNORED = 'Reported Media Ignored';
export const TEXT_TO_DOWNLOAD_CLICKED = 'Text To Download Clicked';
export const TEXT_TO_DOWNLOAD_SENT = 'Text To Download Sent';
export const WEB_SESSION_STARTED = 'Web Session Started';
export const VIDEO_PLAYBACK_INTERACTION = 'Video Playback Interaction';
