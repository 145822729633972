import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { range } from 'lodash';
import { colors } from 'shared/utils';

const skeletonKeyFrames = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

interface Props {
  width?: number | null;
  height?: number | null;
  circle?: boolean;
  count?: number;
}

export function Skeleton({ width = null, height = null, circle = false, count = 1 }: Props) {
  return (
    <Wrapper role="status">
      {range(count).map((_, idx) => (
        <SkeletonSd key={idx} width={width} height={height} circle={circle}>
          &zwnj;
        </SkeletonSd>
      ))}
    </Wrapper>
  );
}

// Heavily based on: https://github.com/dvtng/react-loading-skeleton/blob/master/src/skeleton.js
const SkeletonSd = styled.span<Props>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  background-color: ${colors.eggWhite};
  background-image: linear-gradient(
    90deg,
    ${colors.eggWhite},
    ${colors.offWhite},
    ${colors.eggWhite}
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: ${props =>
    props.width !== null && props.height !== null && props.circle ? '50%' : '4px'};
  display: inline-block;
  line-height: 1.5;
  animation: ${skeletonKeyFrames} 1.2s ease-in-out infinite;
`;

const Wrapper = styled.span`
  display: inline-flex;
  flex-direction: column;

  ${SkeletonSd} {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
