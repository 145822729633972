import styled from '@emotion/styled';
import {
  color,
  display,
  space,
  typography,
  variant,
  compose,
  DisplayProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';

type FontSize = 'small' | 'medium' | 'large';

type Props = DisplayProps &
  SpaceProps &
  TypographyProps & {
    // the size prop maps to the 3 variant font sizes that caption text can be
    // as defined by the type scale in our design system
    size?: FontSize | FontSize[];
    as?: 'p' | 'span' | 'figcaption';
  };

export const Caption = styled.span<Props>`
  ${compose(
    variant({
      prop: 'size',
      variants: {
        small: {
          fontSize: 0, // 11px
        },
        medium: {
          fontSize: 1, // 12px
        },
        large: {
          fontSize: 2, // 14px
        },
      },
    }),
    color,
    display,
    space,
    typography
  )};
`;

Caption.defaultProps = {
  size: 'medium',
};
