import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../utils';

type Props = {
  label: string;
  name?: string;
  value: string;
  isChecked?: boolean;
  onChange?: (e: React.SyntheticEvent) => void;
};

function RadioButton({ label, name, value, isChecked, onChange }: Props) {
  return (
    <LabelSd>
      <HiddenRadioSd
        type="radio"
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
      />
      <RadioSd isChecked={isChecked} label={label} value={value} />
      {label && <span>{label}</span>}
    </LabelSd>
  );
}

export default RadioButton;

const LabelSd = styled('label')`
  position: relative;
  display: flex;
  margin-bottom: 12px;
  color: ${colors.black};
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const RadioSd = styled('div')<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 16px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: ${props => (props.isChecked ? colors.black : colors.grayLight)};
  transition: background-color 0.1s ease-out;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.isChecked ? colors.black : 'transparent')};
  }
`;

const HiddenRadioSd = styled('input')`
  position: absolute;
  opacity: 0;
`;
