import React from 'react';
import { colors } from 'shared/utils';

type Props = {
  size?: number;
  color?: string;
};

function GridIcon({ size = 24, color = colors.black }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g fill={color} fillRule="evenodd">
        <rect width="11" height="11" y="13" />
        <rect width="11" height="11" x="13" y="13" />
        <rect width="11" height="11" />
        <rect width="11" height="11" x="13" />
      </g>
    </svg>
  );
}

export default GridIcon;
