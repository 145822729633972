import styled from '@emotion/styled';
import { ElementType } from 'react';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  RequiredTheme,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
  Theme,
  ThemeValue,
  typography,
  TypographyProps,
} from 'styled-system';
import theme from '../theme';

// GapProps interface defines support for the follow CSS properties
// [gap](https://developer.mozilla.org/en-US/docs/Web/CSS/gap)
// [row-gap](https://developer.mozilla.org/en-US/docs/Web/CSS/row-gap)
// [column-gap](https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap)
interface GapProps<ThemeType extends Theme = RequiredTheme, TVal = ThemeValue<'space', ThemeType>> {
  gap?: ResponsiveValue<TVal, ThemeType>;
  rowGap?: ResponsiveValue<TVal, ThemeType>;
  columnGap?: ResponsiveValue<TVal, ThemeType>;
}

type Props = BackgroundProps &
  BorderProps &
  BoxShadowProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  SpaceProps & {
    as?: ElementType;
  } & PositionProps &
  TypographyProps &
  GapProps;

export type BoxProps = Props;

export const Box = styled.div<Props>`
  box-sizing: border-box;
  min-width: 0;
  ${compose(
    background,
    border,
    boxShadow,
    color,
    flexbox,
    grid,
    layout,
    space,
    position,
    typography
  )};
  ${system({
    gap: { property: 'gap', scale: 'space', defaultScale: theme.space },
    rowGap: { property: 'rowGap', scale: 'space', defaultScale: theme.space },
    columnGap: { property: 'columnGap', scale: 'space', defaultScale: theme.space },
  })};
`;
