import * as deviceUtils from 'shared/utils/device';
import { generateUniqueId } from '../../cookies';
import { getCategory } from './page-categorizer';

const DEVICE_TYPES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  OTHER: 'other',
};

const OS_NAMES = {
  IOS: 'iOS',
  ANDROID: 'android',
  MAC_OS: 'mac os',
  WINDOWS: 'windows',
  OTHER: 'other',
};

type UserProperties = {
  appId: string;
  isSignedIn: boolean;
  id?: string;
};

type WebContextProperties = {
  url: string;
  userAgent: string;
  pagePath: string;
  pageTitle: string;
  pageCategory: string;
  referrer: string;
  browserSessionId: string;
  anonymousId: string;
  deviceType: string;
  osName: string;
};

function buildUserProperties(appId: string = '', userId?: number): UserProperties {
  const userProps = {
    appId,
    isSignedIn: false,
  };

  if (!userId) return userProps;

  return {
    ...userProps,
    isSignedIn: true,
    id: `${userId}`,
  };
}

function getDeviceType(): string {
  if (deviceUtils.isTablet()) {
    return DEVICE_TYPES.TABLET;
  } else if (deviceUtils.isMobile()) {
    return DEVICE_TYPES.MOBILE;
  } else if (deviceUtils.isDesktop()) {
    return DEVICE_TYPES.DESKTOP;
  } else {
    return DEVICE_TYPES.OTHER;
  }
}

function getOsName(): string {
  if (deviceUtils.isIOS()) {
    return OS_NAMES.IOS;
  } else if (deviceUtils.isAndroid()) {
    return OS_NAMES.ANDROID;
  } else if (deviceUtils.isWindows()) {
    return OS_NAMES.WINDOWS;
  } else if (deviceUtils.isMacOS()) {
    return OS_NAMES.MAC_OS;
  } else {
    return OS_NAMES.OTHER;
  }
}

export function getPagePath(): string {
  let pagePath = location.pathname;
  if (location.hash) pagePath += location.hash;
  if (location.search) pagePath += location.search;
  return pagePath;
}

function buildWebContextProperties(sId: string = ''): WebContextProperties {
  const pagePath = getPagePath();

  return {
    url: window.location.href,
    userAgent: navigator.userAgent,
    pagePath,
    pageTitle: document.title,
    pageCategory: getCategory(pagePath),
    referrer: document.referrer,
    browserSessionId: sId,
    anonymousId: '',
    deviceType: getDeviceType(),
    osName: getOsName(),
  };
}

interface CantorProps {
  appId?: string;
  sessionId?: string;
  occurred?: boolean;
}

interface CantorProperties {
  eventId: string;
  timestamp: string;
  sentAt: string;
  eventTopic: string;
  eventBody: CantorProps;
  userProperties: UserProperties;
  webContextProperties: WebContextProperties;
}

export function buildCantorProperties(
  eventName: string = '',
  properties: CantorProps = {},
  userId?: number
): CantorProperties {
  const start = new Date().toISOString();
  const { appId, sessionId } = properties;

  return {
    eventId: generateUniqueId(),
    timestamp: start,
    sentAt: start,
    eventTopic: eventName,
    eventBody: properties,
    userProperties: buildUserProperties(appId, userId),
    webContextProperties: buildWebContextProperties(sessionId),
  };
}

interface MixpanelProperties {
  event: string;
  userId: string;
  body: {
    referrer: string;
    userAgent: string;
    platform: string;
    pagePath: string;
    pageCategory: string;
    deviceType: string;
    osName: string;
    timeStamp: string;
  };
}

export function buildMixpanelProperties(
  eventName: string = '',
  properties: Object = {},
  userId?: number
): MixpanelProperties {
  const pagePath = getPagePath();

  return {
    event: eventName,
    userId: `${userId || ''}`,
    body: {
      ...properties,
      referrer: document.referrer,
      userAgent: navigator.userAgent,
      platform: 'WEB',
      pagePath,
      pageCategory: getCategory(pagePath),
      deviceType: getDeviceType(),
      osName: getOsName(),
      timeStamp: new Date().toISOString(),
    },
  };
}

export function buildPayload(eventName: string = '', properties: Object = {}, userId?: number) {
  return {
    cantorProperties: buildCantorProperties(eventName, properties, userId),
    mixpanelProperties: buildMixpanelProperties(eventName, properties, userId),
  };
}
