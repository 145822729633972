import { css } from '@emotion/react';
import { ASSETS_URL } from 'shared/constants';

export default css`
@font-face {
  font-family: "VSCO Gothic";
  src: url("${ASSETS_URL}/font/vsco-gothic-light/VSCOGothic-Light.woff2") format("woff2"),
    url("${ASSETS_URL}/font/vsco-gothic-light/VSCOGothic-Light.woff") format("woff"),
    url("${ASSETS_URL}/font/vsco-gothic-light/VSCOGothic-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "VSCO Gothic";
  src: url("${ASSETS_URL}/font/vsco-gothic-book/VSCOGothic-Book.woff2") format("woff2"),
    url("${ASSETS_URL}/font/vsco-gothic-book/VSCOGothic-Book.woff") format("woff"),
    url("${ASSETS_URL}/font/vsco-gothic-book/VSCOGothic-Book.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "VSCO Gothic";
  src: url("${ASSETS_URL}/font/vsco-gothic-medium/VSCOGothic-Medium.woff2") format("woff2"),
    url("${ASSETS_URL}/font/vsco-gothic-medium/VSCOGothic-Medium.woff") format("woff"),
    url("${ASSETS_URL}/font/vsco-gothic-medium/VSCOGothic-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "VSCO Gothic";
  src: url("${ASSETS_URL}/font/vsco-gothic-semibold/VSCOGothic-SemiBold.woff2") format("woff2"),
    url("${ASSETS_URL}/font/vsco-gothic-semibold/VSCOGothic-SemiBold.woff") format("woff"),
    url("${ASSETS_URL}/font/vsco-gothic-semibold/VSCOGothic-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "VSCO Gothic";
  src: url("${ASSETS_URL}/font/vsco-gothic-bold/VSCOGothic-Bold.woff2") format("woff2"),
    url("${ASSETS_URL}/font/vsco-gothic-bold/VSCOGothic-Bold.woff") format("woff"),
    url("${ASSETS_URL}/font/vsco-gothic-bold/VSCOGothic-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
`;
