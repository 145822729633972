import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { HeadingText, Box, Grid } from 'shared/components';
import { useScrollPosition } from 'shared/hooks';
import BlogNav from './BlogNav';
import { breakPoints, mediaQuery } from 'shared/utils';

// pixel height of header section with title and nav plus margin/padding
const HEADER_OFFSET = 76;
// based off eyeballing it for how far scrolled down page
const SCROLL_THRESHOLD = 275;

type Props = {
  title: string;
  location: Location;
  showNav?: boolean;
};

function Header({ title, location, showNav = true }: Props) {
  const scrollPosition = useScrollPosition();
  const shouldCollapseHeader = scrollPosition.y > SCROLL_THRESHOLD;

  return (
    <Box
      as="header"
      px={6}
      pt={6}
      pb={shouldCollapseHeader ? 3 : 6}
      borderBottom="1px solid"
      borderColor="gray"
      css={[sx.header, shouldCollapseHeader && sx.headerCollapsed]}
    >
      <Grid
        gridTemplateColumns="1fr 1fr"
        justifyContent="space-between"
        alignItems="center"
        mb={showNav ? [5, 6] : 0}
      >
        <Box display="flex" alignItems="center">
          <Link to="/">
            <HeadingText
              as={location.pathname === '/' ? 'h1' : 'h3'}
              display="inline-block"
              fontSize={[4, 5]}
              fontWeight={[null, 'book']}
            >
              {title}
            </HeadingText>
          </Link>
        </Box>

        <nav css={sx.nav}>
          <a href="https://vsco.co/about/company">About</a>
          <a href="https://vsco.co/about/careers">Careers</a>
        </nav>
      </Grid>

      {showNav && <BlogNav location={location} />}
    </Box>
  );
}

export default Header;

const sx = {
  header: css`
    z-index: 6;
    position: sticky;
    top: 0;
    background: hsla(0, 0%, 100%, 0.75);
    backdrop-filter: blur(16px);
    transition: all 0.2s cubic-bezier(0.76, 0, 0.24, 1);
  `,
  headerCollapsed: css`
    transform: translateY(-${HEADER_OFFSET}px);

    ${mediaQuery(breakPoints.SMALL)} {
      transform: translateY(-${HEADER_OFFSET + 20}px);
    }
  `,
  nav: css`
    justify-self: end;
    font-size: 16px;

    ${mediaQuery(breakPoints.SMALL)} {
      font-size: 24px;
    }

    a {
      margin-left: 20px;

      ${mediaQuery(breakPoints.SMALL)} {
        margin-left: 40px;
      }
    }
  `,
};
