import { useState, ChangeEvent, FormEvent } from 'react';
import { getErrorMessage } from 'shared/utils';

type ValidatorFn = (...args: any[]) => string;

type Validator = {
  minLength?: number;
  maxLength?: number;
  regEx?: RegExp;
  error: string;
};

type Validators = {
  validate?: Validator | Validator[] | ValidatorFn;
  isRequired?: boolean; // Defaults to false
};

// this gives us the ability to validate the response returned from the request
// for example, on updatePassword on account profile page we want to ensure that
// data.response.passwordUpdated is true otherwise display an error message
type ValidatorResponse = {
  response?: { [key: string]: string };
};

export function useForm<Data extends { [key: string]: string }>(
  initialState: Data,
  validators: { [K in keyof Partial<Data>]: Validators } & ValidatorResponse,
  onSubmit: (values: Data) => Promise<any>
) {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState<{ [K in keyof Data]?: string | null } & { network: string }>(
    { network: '' }
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [response, setResponse] = useState<{} | null>(null);

  function handleValidateSchema(validator: Validator, values: Data, field: keyof Data) {
    if (validator.regEx && !validator.regEx.test(values[field])) {
      return validator.error;
    } else if (validator.minLength && values[field].length < validator.minLength) {
      return validator.error;
    } else if (validator.maxLength && values[field].length > validator.maxLength) {
      return validator.error;
    }
    return null;
  }

  // TODO (WAM): Investigate updating useForm hook to not consider empty inputs as
  // regex validation errors - https://teamvsco.atlassian.net/browse/WWW-1116.
  function handleValidation() {
    // clear any previous errors
    setErrors({ network: '' });

    let errs: { [K in keyof Data]?: string | null } = {};
    Object.keys(validators).forEach(k => {
      const key = k as keyof Data;
      const isRequired = validators[key].isRequired || false;
      const validator = validators[key].validate;
      if (isRequired && !values[key]) {
        errs[key] = `This field is required.`;
        return;
      }
      if (typeof validator === 'function') {
        errs[key] = validator(values[key]);
      } else if (Array.isArray(validator)) {
        validator.forEach(v => {
          errs[key] = handleValidateSchema(v, values, key);
        });
      } else if (validator) {
        errs[key] = handleValidateSchema(validator, values, key);
      }
    });
    return errs;
  }

  async function handleSubmit(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();

    const errs = handleValidation();
    const hasErrors = !!Object.keys(errs).filter(k => {
      const key = k as keyof Data;
      return !!errs[key];
    }).length;
    if (hasErrors) {
      setErrors(state => ({ ...state, ...errs }));
      return;
    }

    try {
      setIsSubmitting(true);
      setResponse(null);
      const data = await onSubmit(values);
      if (data?.error) throw new Error(data.error);
      if (data?.response && validators.response) {
        // TODO: I still think we can utilize Error Boundaries here for any
        // network or server errors, similar to in-app, form state errors are
        // colocated with the form itself but network/server errors are shown
        // as a global banner/toast
        // validators.response should only ever have 1 item in the object
        // which maps the value returned from the response in data
        const [key] = Object.keys(validators.response);
        if (!data.response[key]) throw new Error(validators.response[key]);
      }
      setHasChanged(false);
      setResponse(data);
    } catch (err) {
      setErrors(state => ({ ...state, network: getErrorMessage(err) }));
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleChange(
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) {
    const { name, value } = evt.target;
    setValues(values => ({
      ...values,
      [name]: value,
    }));
    setHasChanged(true);
  }

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    hasChanged,
    response,
  };
}
