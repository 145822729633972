import React from 'react';
import { Global } from '@emotion/react';

import sanitizeStyles from './css/sanitize.css';
import baseTypeStyles from './css/base-type.css';
import baseElementStyles from './css/base-elements.css';
import utilStyles from './css/utils.css';
import utilSpaceStyles from './css/util-space.css';

function GlobalStyles() {
  return (
    <>
      <Global styles={sanitizeStyles} />
      <Global styles={baseTypeStyles} />
      <Global styles={baseElementStyles} />
      <Global styles={utilStyles} />
      <Global styles={utilSpaceStyles} />
    </>
  );
}

export default GlobalStyles;
