import React, { ReactElement, ChangeEvent } from 'react';
import { RadioButton, Label, Box } from 'shared/components';

type Props = {
  children: ReactElement[];
  name: string;
  value: string;
  label?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

function RadioGroup({ children, name, value, label, onChange }: Props) {
  function renderRadiobuttons() {
    return React.Children.map(children, child => {
      if (child.type === RadioButton) {
        return React.cloneElement(child, {
          isChecked: value === child.props.value,
          name: name,
          onChange: onChange,
        });
      }
      return child;
    });
  }

  return (
    <Box mb={20}>
      {!!label && (
        <Label htmlFor={name} isBold>
          {label}
        </Label>
      )}

      {renderRadiobuttons()}
    </Box>
  );
}

export default RadioGroup;
