import { Session, SessionLang } from 'shared/types';

let currentSession: Session;

function getCurrentSession(): Session {
  if (!currentSession) {
    throw new Error(
      '[SESSION] looks like you are trying to access a session that has not been set'
    );
  }
  return currentSession;
}

export function set(session: Session) {
  currentSession = session;
}

export function getCountry(): string {
  return getCurrentSession().country || '';
}

export function getEmail(): string {
  return getCurrentSession().email || '';
}

export function getLang(): SessionLang {
  return getCurrentSession().lang || {};
}

export function getSessionId(): string {
  return getCurrentSession().sId || '';
}

export function getAccessToken(): string {
  return getCurrentSession().tkn || '';
}

export function getUserId(): number {
  return getCurrentSession().userId || 0;
}
