import React from 'react';
import { colors } from 'shared/utils';

type Props = {
  size?: number;
  color?: string;
};

function ListIcon({ size = 24, color = colors.black }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g stroke={color} strokeWidth="3" strokeLinecap="square">
        <line x1="24" y1="2" y2="2" />
        <line x1="24" y1="12" y2="12" />
        <line x1="24" y1="22" y2="22" />
      </g>
    </svg>
  );
}

export default ListIcon;
