import { Settings } from 'shared/types';
import { isLocal } from 'shared/utils/env';

let currentSettings: Settings;

export function set(settings: Settings) {
  currentSettings = settings;
}

export function getDomain(): string {
  return isLocal ? '' : currentSettings?.domain || '';
}
