import { css, keyframes } from '@emotion/react';

// Animation utilities and classes

const fadeInOutKeys = keyframes`
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`;

export const fadeInOut = css`
  opacity: 0;
  animation-name: fade-in-out;
  animation-duration: 2.5s;
  animation-timing-function: ease-out;

  ${fadeInOutKeys};
`;

const fadeInKeys = keyframes`
  0% { background-color: inherit; }
  100% { background-color: transparent; }
`;

export const fadeIn = css`
  background-color: transparent;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-out;

  ${fadeInKeys};
`;
