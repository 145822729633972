import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { colors } from 'shared/utils';
import { Label, Caption, Box } from 'shared/components';

type Props = {
  name: string;
  value: string;
  children: ReactElement[];
  label?: string;
  message?: string | ReactElement;
  hideLabel?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export function Select({
  name,
  value,
  children,
  label,
  message,
  hideLabel,
  required,
  isInvalid = false,
  onChange,
}: Props) {
  return (
    <Box mb={20}>
      {!!label && (
        <Label htmlFor={name} isHidden={hideLabel} isRequired={required} isBold>
          {label}
        </Label>
      )}

      <SelectSd name={name} value={value} isInvalid={isInvalid} onChange={onChange}>
        {children}
      </SelectSd>

      {!!message && (
        <Caption
          display="block"
          size="medium"
          color={isInvalid ? colors.red : ''}
          fontWeight="medium"
          mt="2"
        >
          {message}
        </Caption>
      )}
    </Box>
  );
}

interface SelectSdProps {
  isInvalid: boolean;
}

const SelectSd = styled('select')<SelectSdProps>`
  display: block;
  width: 100%;
  border: ${props => props.theme.borders[1]};
  border-color: ${props =>
    props.isInvalid ? props.theme.colors.red : props.theme.colors.grayLight};
  border-radius: ${props => props.theme.radii[1]};
  appearance: none;
  padding: ${props => `${props.theme.space[2]}px  ${props.theme.space[3]}px`};
  color: ${props => props.theme.colors.charcoal};
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23222%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0, 0;
  background-size: 0.65em auto;
`;
