import React from 'react';
import { css, Global } from '@emotion/react';
import GlobalStyles from 'shared/GlobalStyles';
import Header from './header';
import Footer from './footer';

type Props = {
  location: Location;
  title: string;
  children: React.ReactNode;
  showNav?: boolean;
};

const Layout = ({ location, title, children, showNav }: Props) => {
  return (
    <>
      <Global styles={sx.stickyFooter} />
      <GlobalStyles />

      <Header title={title} location={location} showNav={showNav} />

      <main>{children}</main>

      <Footer />
    </>
  );
};

export default Layout;

const sx = {
  stickyFooter: css`
    html,
    body,
    #___gatsby {
      height: 100%;
    }
    #gatsby-focus-wrapper {
      min-height: 100%;
      display: grid;
      grid-template-rows: auto 1fr auto;
    }
    #gatsby-focus-wrapper > footer {
      grid-row-start: 3;
      grid-row-end: 4;
    }
  `,
};
