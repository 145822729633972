import React, { useEffect } from 'react';
import { colors } from 'shared/utils';
import { validateEmail, validatePwLength } from 'shared/utils/validator';
import { Input, Spinner, ErrorMessage, Button, ShowPassword } from 'shared/components';
import { useForm } from 'shared/hooks';
import * as userApi from 'shared/api/userApi';

const forgotLinkSX = {
  display: 'block',
  marginTop: 20,
  fontSize: 12,
  color: colors.grayDark,
};

type Props = {
  onSuccess: () => void;
};

function LoginForm({ onSuccess }: Props) {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    hasChanged,
    errors,
    response,
  } = useForm(
    {
      email: '',
      password: '',
    },
    {
      email: {
        validate: validateEmail,
        isRequired: true,
      },
      password: {
        validate: validatePwLength,
        isRequired: true,
      },
    },
    onSubmit
  );

  useEffect(() => {
    if (response) onSuccess();
  }, [onSuccess, response]);

  function onSubmit(login: userApi.LoginReq) {
    return userApi.login(login);
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <fieldset>
        <Input
          name="email"
          type="email"
          label="Email"
          hideLabel
          placeholder="Email"
          autoFocus
          bordered
          value={values.email}
          onChange={handleChange}
          isInvalid={!!(errors.email || errors.network)}
        />

        <ShowPassword
          isInvalid={!!(errors.password || errors.network)}
          value={values.password}
          onChange={handleChange}
        />
      </fieldset>

      <ErrorMessage error={errors.email || errors.password || errors.network} />

      <Button type="submit" isFullWidth={true} disabled={!hasChanged || isSubmitting}>
        {isSubmitting ? <Spinner color={colors.white} /> : 'Log in'}
      </Button>

      <a href="https://vsco.co/user/forgotpassword" css={forgotLinkSX}>
        Forgot your password?
      </a>
    </form>
  );
}

export default LoginForm;
