/**
 * Utility functions related to styling
 */

// the default browser font size -- this can be used for em/rem layouts
const FONT_BASE = 16;

/**
 * A map of all of our responsive breakpoints.
 */
export const breakPoints = {
  X_SMALL: 480,
  SMALL: 576,
  MEDIUM: 768,
  LARGE: 1024,
  XLARGE: 1200,
} as const;

// type Breakpoint$Values = $Values<Breakpoints>;
// type MediaFeature = 'min-width' | 'max-width' | 'min-height' | 'max-height';
// type Breakpoint = [number, MediaFeature];

const DEFAULT_FEATURE = 'min-width';

/**
 * mediaQuery returns a media query string for a given breakpoint.
 * @param {...Array} breakpoints An array or array of arrays of breakpoints
 * @returns {string} A media query (Ex. `@media (min-width: 30em)`)
 * @example mediaQuery(480, 'max-width')
 *          // Result: @media (max-width: 30em)
 * @example mediaQuery([480, 'min-width'], [1024, 'max-width'])
 *          // Result: @media (min-width: 30em) and (max-width: 64em)
 * @example mediaQuery([480, 'min-width'], [1280, 'max-width'], [[767], [80, 'max-height']])
 *          // Result: @media (min-width: 30em) and (max-width: 80em) and (min-width: 47.9375em), (max-height: 5em)
 */
export function mediaQuery(...breakpoints: Array<any>): string {
  const [breakpoint, feature = DEFAULT_FEATURE] = breakpoints;

  if (Array.isArray(breakpoint)) {
    const bps = [...breakpoints].map(bp => {
      const [point, feat = DEFAULT_FEATURE] = bp;
      if (Array.isArray(point)) {
        // Example: mediaQuery([480, 'min-width'], [1024, 'max-width'])
        // Result:  @media (min-width: 30em) and (max-width: 64em)
        return bp
          .reduce(
            (broken: [string?], toBreak: [number, string?]) => [
              ...broken,
              `(${toBreak[1] || DEFAULT_FEATURE}: ${toBreak[0] / FONT_BASE}em)`,
            ],
            []
          )
          .join(', ');
      }
      return `(${feat}: ${point / FONT_BASE}em)`;
    });
    return `@media ${bps.join(' and ')}`;
  }

  return `@media (${feature}: ${breakpoint / FONT_BASE}em)`;
}

enum Spacings {
  NONE = 0,
  X_SMALL = 4, // .25rem
  SMALL = 8, // .5rem
  MEDIUM = 16, // 1rem
  LARGE = 32, // 2rem
  X_LARGE = 64, // 4rem
  XX_LARGE = 128, // 8rem
  XXX_LARGE = 256, // 16rem
}

/**
 * spacing is a sizing scale used for adding paddings and
 * margins to elements. You can use this values directly or
 * via the helper functions provided in this file. This scale grows by
 * powers of two and will start at 4px (16/4) on most devices so it is guaranteed
 * to always produce, whole, even numbers.
 *
 * E.X.
 *
 * const sx = css`
 *    padding: ${rem(spacings.MEDIUM)};
 * `;
 */
export const spacings = {
  NONE: Spacings.NONE,
  X_SMALL: Spacings.X_SMALL,
  SMALL: Spacings.SMALL,
  MEDIUM: Spacings.MEDIUM,
  LARGE: Spacings.LARGE,
  X_LARGE: Spacings.X_LARGE,
  XX_LARGE: Spacings.XX_LARGE,
  XXX_LARGE: Spacings.XXX_LARGE,
};

export const rem = (sp: Spacings): string => {
  return `${sp / 16}rem`;
};

/* z-index constants */
export const zIndex = {
  NAV: 1,
  PIN_OVERLAY: 1,
  IMAGES_OVERLAP: 1,
  IMAGES_OVERLAP_PROFILE_IMAGE: 3,
  NAV_BAR: 900,
  MODAL: 999,
} as const;

export const navHeight = '84px';

export function centerAbsolute(size: number) {
  return `
    top: 50%;
    margin-top: -${size / 2}px;
    left: 50%;
    margin-left: -${size / 2}px;
  `;
}
