import React, { CSSProperties } from 'react';
import { colors } from 'shared/utils';

interface Props {
  size?: number;
  fill?: string;
  style?: CSSProperties;
}

const ShowPasswordIcon = ({ size = 24, fill = colors.black, style = {} }: Props) => (
  <svg
    width={size}
    height={size}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Show Password</title>
    <g fill={fill} fillRule="evenodd">
      <polygon points="0 13 4.5 13 4.5 11 0 11" />
      <polygon points="5.99 7.404 2.808 4.222 4.222 2.808 7.404 5.99" />
      <polygon points="11 4.5 13 4.5 13 0 11 0" />
      <polygon points="18.01 7.404 16.596 5.99 19.779 2.808 21.193 4.222" />
      <polygon points="19.5 13 24 13 24 11 19.5 11" />
      <path d="M8.7031,11 L15.2031,11 C14.7851,9.556 13.4861,8.5 11.9531,8.5 C10.4201,8.5 9.1211,9.556 8.7031,11 L8.7031,11 Z M17.3441,13 L6.5621,13 L6.5621,12 C6.5621,8.967 8.9801,6.5 11.9531,6.5 C14.9261,6.5 17.3441,8.967 17.3441,12 L17.3441,13 Z" />
    </g>
  </svg>
);

export default ShowPasswordIcon;
