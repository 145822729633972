import React from 'react';
import { colors } from 'shared/utils';

interface Props {
  width?: number;
  height?: number;
}

function IconVideoControlsPlay({ width = 40, height = 40 }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
    >
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <path fill={colors.white} d="M15.259 11.861l12.12 7.678-12.12 7.679z" />
        <circle stroke={colors.white} strokeWidth="2" cx="19" cy="19" r="19" />
      </g>
    </svg>
  );
}

export default IconVideoControlsPlay;
