import styled from '@emotion/styled';
import { ASSETS_URL } from 'shared/constants';

type MoreOptionsMenu = {
  isVertical?: boolean;
};

export const MoreOptionsMenu = styled.button<MoreOptionsMenu>`
  width: 24px;
  height: 24px;
  background-image: url("${ASSETS_URL}/images/icons/three-dots.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: ${props => (props.isVertical ? 'rotate(90deg)' : null)};

  &:hover, &:focus {
    background-image: url("${ASSETS_URL}/images/icons/three-dots-black.svg");
  }
`;
