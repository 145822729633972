export const PAGE_CATEGORIES = {
  ABOUT: 'about',
  COLLECTION: 'collection',
  DOWNLOAD: 'download',
  REDEEM: 'redeem',
  FEED: 'feed',
  FOLLOWING: 'following',
  HOMEPAGE: 'homepage',
  IMAGE_DETAIL: 'image detail',
  VIDEO_DETAIL: 'video detail',
  JOURNAL: 'journal',
  JOURNAL_DETAIL: 'journal detail',
  SEARCH: 'search',
  USER_ACCT: 'user account',
  PROFILE: 'profile',
  CRT: 'crt',
};

function genericCategorizer(segment: string): string {
  switch (segment) {
    case 'feed':
      return PAGE_CATEGORIES.FEED;
    case 'following':
      return PAGE_CATEGORIES.FOLLOWING;
    case 'download':
      return PAGE_CATEGORIES.DOWNLOAD;
    case 'redeem':
      return PAGE_CATEGORIES.REDEEM;
    case 'search':
      return PAGE_CATEGORIES.SEARCH;
    case 'about':
      return PAGE_CATEGORIES.ABOUT;
    case 'user':
      return PAGE_CATEGORIES.USER_ACCT;
    case 'reports':
      return PAGE_CATEGORIES.CRT;
    case '':
      return PAGE_CATEGORIES.HOMEPAGE;
    default:
      return '';
  }
}

function profileCategorizer(segments: string[]): string {
  switch (segments[0]) {
    case 'journal':
      if (segments[1] === 'p') return PAGE_CATEGORIES.JOURNAL;
      return PAGE_CATEGORIES.JOURNAL_DETAIL;
    case 'collection':
      return PAGE_CATEGORIES.COLLECTION;
    case 'gallery':
      return PAGE_CATEGORIES.PROFILE;
    case 'media':
      return PAGE_CATEGORIES.IMAGE_DETAIL;
    case 'video':
      return PAGE_CATEGORIES.VIDEO_DETAIL;
    default:
      return '';
  }
}

export function getCategory(path: string): string {
  const segments = path.split('/');
  let category = genericCategorizer(trimQueryParams(segments[1]));

  if (category) return category;

  category = profileCategorizer([segments[2], segments[3]]);
  if (!category) {
    console.error('[getCategory] could not categorize page!!', path);
    return '';
  }
  return category;
}

function trimQueryParams(segment: string): string {
  const [returnSegment] = segment.split('?')[0].split('#');
  return returnSegment;
}
