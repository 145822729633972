import {
  API_VERSION_2,
  API_VERSION_3,
  FORM_TYPE,
  HttpMethods,
  request,
} from 'shared/api/apiClient';
import { User } from '../types';

export type UpdateUserReq = {
  firstname: string;
  lastname: string;
  email: string;
};

export function updateUser(userId: number, user: UpdateUserReq): Promise<User> {
  return request(`${API_VERSION_2}/users/${userId}`, {
    method: 'POST',
    body: user,
    headers: { 'Content-Type': FORM_TYPE },
  });
}

interface UpdatePasswordReq {
  currentPass: string;
  newPass: string;
  confirmPass: string;
}

export interface UpdatePasswordRes {
  passwordUpdated: boolean;
}

export function updatePassword(req: UpdatePasswordReq): Promise<UpdatePasswordRes> {
  return request(`${API_VERSION_2}/users/changepassword`, {
    method: HttpMethods.POST,
    body: req,
    headers: { 'Content-Type': FORM_TYPE },
  });
}

// TODO: add response type
export function forgotPassword(userId: string): Promise<any> {
  return request(`${API_VERSION_2}/users/forgotpassword?user_identifier=${userId}`);
}

export type ResetPasswordReq = {
  expire: string;
  newPass: string;
  confirmPass: string;
};

// TODO: add response type
export function resetPassword(body: ResetPasswordReq): Promise<any> {
  // TODO (wam): investigate whether this endpoint can accept JSON payloads
  return request(`${API_VERSION_2}/users/resetpassword`, {
    method: HttpMethods.POST,
    body,
    headers: { 'Content-Type': FORM_TYPE },
  });
}

export enum GDPROperation {
  UNKNOWN,
  DATA_ACCESS,
  DATA_ERASE,
}

export enum GDPRAccessStatus {
  UNKNOWN,
  PROCESSING,
  ERROR,
  COMPLETE,
}

export type PersonalDataAccessRequest = {
  operation: GDPROperation;
  password?: string;
};

export type PersonalDataAccessResponse = {
  status: GDPRAccessStatus;
  url?: string;
  expiresAt?: string;
  message?: string;
};

export function personalDataAccess(
  operation: GDPROperation = GDPROperation.DATA_ACCESS,
  password?: string
): Promise<PersonalDataAccessResponse> {
  const body: PersonalDataAccessRequest = {
    operation,
  };

  if (operation === GDPROperation.DATA_ERASE) {
    body.password = password;
  }

  return request(`${API_VERSION_3}/users/personal-data-access`, {
    method: HttpMethods.POST,
    body,
  });
}

export enum RevokeStatus {
  UNKNOWN,
  ERROR,
  COMPLETE,
}

export type RevokeUserRequest = {
  userId: number;
  // a token indicates we're signing out of 1 device (aka the current web session)
  token?: string;
};

export type RevokeUserResponse = {
  status: RevokeStatus;
};

// used to sign out of all devices as well as the user's current web session
export function revokeUser(body: RevokeUserRequest): Promise<RevokeUserResponse> {
  return request(`${API_VERSION_2}/users/revoke`, {
    method: HttpMethods.POST,
    body,
  });
}

export function getCurrent(withSites: boolean = false): Promise<User> {
  const url = `${API_VERSION_2}/users${withSites ? '?site_requested=1' : ''}`;

  return request(url);
}

export type LoginReq = {
  email: string;
  password: string;
};

// NOTE: unlike the endpoints above, this endpoint is implemented in Aurora's
// express app.  We're using this endpoint as a proxy for the Rosco authentication
// service
export function login(body: LoginReq): Promise<any> {
  return fetch('/auth/login', {
    method: HttpMethods.POST,
    mode: 'same-origin',
    credentials: 'include',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response: Response) => response.json());
}
