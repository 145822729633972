import { camelizeKeys, decamelizeKeys } from 'humps';
import { polyfill } from 'es6-promise';
import 'isomorphic-fetch';
import { getApiRoot, isLocal, isServer, toUrlEncoded, getErrorMessage } from 'shared/utils';
import * as session from 'shared/utils/session';
import * as appSettings from 'shared/utils/appSettings';

polyfill();

const FETCH_TIMEOUT = 5000;
const SERVER_PROTOCOL = 'http';
const API_ROOT = '/api';
const API_SERVER_ROOT = `${getApiRoot()}`;
export const JSON_TYPE = 'application/json';
export const FORM_TYPE = 'application/x-www-form-urlencoded';

export const API_VERSION_1 = '1.0';
export const API_VERSION_2 = '2.0';
export const API_VERSION_3 = '3.0';
export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

function buildUrl(endpoint: string, service: string): string {
  let prefix;
  let domain: string = appSettings.getDomain();

  if (isServer()) {
    if (service && !isLocal) {
      prefix = `${SERVER_PROTOCOL}://${service}${API_ROOT}`;
    } else {
      prefix = API_SERVER_ROOT;
    }
  } else if (domain) {
    prefix = `https://${domain}${API_ROOT}`;
  } else {
    prefix = API_ROOT;
  }

  return endpoint.indexOf(API_ROOT) === -1 ? `${prefix}/${endpoint}` : endpoint;
}

interface Options {
  [index: string]: any;
}

export interface RequestHeaders {
  Authorization?: string;
  'Content-Type'?: string;
  'User-Agent'?: string;
}

function buildOptions(options: Options): RequestInit {
  const opts = { ...options };
  let shouldNotDecamelize = false;

  if (opts.shouldNotDecamelize) {
    shouldNotDecamelize = true;
    delete opts.shouldNotDecamelize;
  }

  const reqOpts: Options = {
    ...opts,
    method: opts.method || HttpMethods.GET,
    credentials: 'same-origin',
    headers: getHeaders(opts.headers),
  };

  function transformBody(body: { [index: string]: string | number }): string | object {
    if (shouldNotDecamelize && reqOpts.headers['Content-Type'] === JSON_TYPE) {
      return JSON.stringify(body);
    } else if (reqOpts.headers['Content-Type'] === FORM_TYPE) {
      return toUrlEncoded(decamelizeKeys(body));
    } else if (reqOpts.headers['Content-Type'] === JSON_TYPE) {
      return JSON.stringify(decamelizeKeys(body));
    }
    return body;
  }

  if (opts.method !== HttpMethods.GET && opts.body) {
    reqOpts.body = transformBody(opts.body);
  }

  return reqOpts;
}

function timeout(promise: Promise<any>, ms: number) {
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  return Promise.race([
    promise,
    (async () => {
      await delay(ms);
      throw new Error('Request timed out');
    })(),
  ]);
}

// TODO (wam) 02-18-21: improve type signature of the 'options' arg.
export function request(
  endpoint: string,
  options: Options = {},
  service: string = ''
): Promise<any> {
  const resourceUrl = buildUrl(endpoint, service);
  const initOptions = buildOptions(options);

  return timeout(fetch(resourceUrl, initOptions), FETCH_TIMEOUT)
    .then((response: Response) => {
      // NOTE: we're reading the payload via the text method to handle the case where an
      // endpoint responds with an empty json response. This is probably temporary
      // until the endpoint is updated to respond with a status code of 204
      return response.text().then(text => {
        return text ? { json: JSON.parse(text), response } : { json: {}, response };
      });
    })
    .then(({ json, response }) => {
      if (response.status === 409 && json.consent) {
        Promise.resolve(json).then(() => {
          if (typeof window !== 'undefined') window.location.href = '/user/consent';
        });
        throw new Error('Need Consent');
      }

      // checking for 401 and 403 in the event that a request fails due to be unauthorized or forbidden
      if (
        response.status >= 500 ||
        response.status === 401 ||
        response.status === 403 ||
        !response.ok
      ) {
        return Promise.reject(json);
      }

      return camelizeKeys(json);
    });
}

function getHeaders(headers: RequestHeaders = {}) {
  let result: { [key: string]: string | number } = {
    'Content-Type': headers['Content-Type'] ?? JSON_TYPE,
    Authorization: headers.Authorization ?? '',
    'X-Client-Platform': 'web',
    'X-Client-Build': 1,
  };

  if (!result.Authorization) {
    try {
      result['Authorization'] = `Bearer ${session.getAccessToken()}`;
    } catch (e) {
      console.error(getErrorMessage(e));
    }
  }

  if (headers['User-Agent']) {
    result['User-Agent'] = headers['User-Agent'];
  }

  return result;
}
