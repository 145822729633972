import React, { ReactElement } from 'react';
import { css } from '@emotion/react';
import { colors } from '../utils';
import { Label, Caption } from 'shared/components';

interface Props {
  label?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  tip?: string;
  message?: string | ReactElement;
  hideLabel?: boolean;
  bordered?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  pattern?: RegExp;
  value: string;
  autoComplete?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  spellCheck?: boolean;
}

// shared styles which can be applied to all *input* types
export const styles = (bordered: boolean = true, disabled: boolean = false) => {
  const height: number = 42;
  const borderPaddingX: number = 12;
  const borderPaddingY: number = 20;

  return css`
    border-spacing: 0;
    border-collapse: separate;
    height: ${height - (bordered ? borderPaddingY * 2 : 0)}px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: ${bordered ? `${borderPaddingY}px ${borderPaddingX}px` : null};
    background-color: ${disabled ? colors.eggWhite : colors.white};
    border: ${bordered ? 1 : 0}px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: ${colors.grayLight};
    border-radius: ${bordered ? 3 : 0}px;
    color: ${disabled ? colors.black : colors.charcoal};
    cursor: default;
    opacity: ${disabled ? 0.9 : null};

    &:focus {
      border-color: ${colors.gray};
    }
  `;
};

const Input = ({
  value,
  onChange,
  placeholder,
  label = '',
  name = '',
  type = 'text',
  tip = '',
  message = '',
  hideLabel = false,
  bordered = true,
  required = false,
  isInvalid = false,
  disabled = false,
  autoFocus,
  autoComplete,
  autoCorrect,
  autoCapitalize,
  spellCheck,
}: Props) => {
  const invalidSx = isInvalid
    ? css`
        border-color: ${colors.red};
      `
    : null;

  const rootSx = css`
    margin-bottom: 20px;
  `;

  // shared input styles
  const inputSx = css`
    display: table;
    ${styles(bordered, disabled)};
    ${invalidSx};
  `;

  return (
    <div css={rootSx}>
      <Label htmlFor={name} isHidden={hideLabel} tip={tip} isRequired={required} isBold>
        {label}
      </Label>

      <input
        value={value}
        onChange={onChange}
        css={inputSx}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        autoCapitalize={autoCapitalize}
        spellCheck={spellCheck}
      />

      {!!message && (
        <Caption
          display="block"
          size="medium"
          color={isInvalid ? 'red' : ''}
          fontWeight="medium"
          mt="2"
        >
          {message}
        </Caption>
      )}
    </div>
  );
};

export default Input;
