import styled from '@emotion/styled';
import { CONTAINER_WIDTH_PERCENTAGE } from '../constants';

type Props = {
  maxWidth?: number;
  style?: { [key: string]: string | number };
};

const Container = styled('div')<Props>`
  width: ${CONTAINER_WIDTH_PERCENTAGE * 100}%;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : null)};
  margin-left: auto;
  margin-right: auto;
  ${props => props.style};
`;

export default Container;
