const fontFallback =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export const fonts = {
  light: {
    fontFamily: `"VSCO Gothic", ${fontFallback}`,
    fontWeight: 200,
  },
  book: {
    fontFamily: `"VSCO Gothic", ${fontFallback}`,
    fontWeight: 400,
  },
  medium: {
    fontFamily: `"VSCO Gothic", ${fontFallback}`,
    fontWeight: 600,
  },
  semibold: {
    fontFamily: `"VSCO Gothic", ${fontFallback}`,
    fontWeight: 700, // Gothic SemiBold is more like bold
  },
  bold: {
    fontFamily: `"VSCO Gothic", ${fontFallback}`,
    fontWeight: 800, // Gothic Bold is more like heavy
  },
};
