import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme } from 'shared/theme';

export const baseBtn = ({ theme }: { theme: Theme }) => css`
  padding-top: 2px; /* to line up with back btn */
  font-size: ${theme.fontSizes[2]};
  font-weight: ${theme.fontWeights.medium};
  text-transform: uppercase;
  justify-self: end;
`;

export const CancelBtn = styled('button')`
  ${baseBtn};
  color: ${props => props.theme.colors.grayDark};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.black};
  }
`;

export const CTABtn = styled('button')`
  ${baseBtn};
  color: ${props => props.theme.colors.grayDark};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.black};
  }
`;

export const BackBtn = styled('button')`
  position: relative;
  width: 24px;
  height: 36px;
  color: ${props => props.theme.colors.grayDark};
  font-size: 30px;
  line-height: 0;

  &::after {
    content: '\\2190';
  }
`;

export const GuidelinesBtn = styled('button')`
  margin-top: ${props => props.theme.space[4]}px;
  margin-bottom: ${props => props.theme.space[4]}px;
  width: 100%;
  font-size: ${props => props.theme.fontSizes[2]};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.blue};
`;

export const GuidelinesSd = styled('ul')`
  font-size: ${props => props.theme.fontSizes[2]};
  list-style-type: disc;
  padding-left: ${props => props.theme.space[4]}px;
  margin-top: ${props => props.theme.space[1]}px;

  li {
    line-height: 1.6;
  }
`;

export const MenuItemSd = styled('button')`
  width: 100%;
  display: block;
  color: ${props => props.theme.colors.black};
  border-bottom: 1px solid ${props => props.theme.colors.grayLight};
  font-weight: ${props => props.theme.fontWeights.book};
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: 1.43;
  padding: 20px 0;
  text-align: left;
`;

export const MenuItemLinkSd = MenuItemSd.withComponent('a');

export const Divider = styled('hr')`
  border-style: solid;
  border-color: ${props => props.theme.colors.grayLight};
  margin-bottom: ${props => props.theme.space[5]}px;
`;

export const Link = styled('a')`
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: ${props => props.theme.fontSizes[2]};
  color: ${props => props.theme.colors.black};
  text-decoration: underline;
`;
