import React, { MouseEvent } from 'react';
import styled from '@emotion/styled';
import Tab from './Tab';

type Props = {
  labels: string[];
  value: number;
  onChange: (index: number) => void;
};

function Tabs({ labels, value, onChange }: Props) {
  function onClickTabItem(event: MouseEvent) {
    const index = (event.target as HTMLButtonElement).dataset.index || '0';
    onChange(parseInt(index, 10));
  }

  return (
    <Nav onClick={onClickTabItem}>
      <ul>
        {labels.map((label, index) => (
          <li key={index}>
            <Tab isActive={index === value} index={index} label={label} />
          </li>
        ))}
      </ul>
    </Nav>
  );
}

export default Tabs;

const Nav = styled('nav')`
  ul {
    display: flex;
    padding-left: 0;
    margin: 0;

    li {
      display: inline-block;
      list-style: none;
      margin-right: 24px;
    }
  }
`;
