import React from 'react';
import { colors } from 'shared/utils';

interface Props {
  width?: number;
  height?: number;
  fill?: string;
  style?: { [selector: string]: string | number };
}

const SadFaceIcon = ({ width = 102, height = width, fill = colors.black, style = {} }: Props) => (
  <svg
    width={width}
    height={height || width}
    style={style}
    viewBox="0 0 102 102"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M51 1C23.4 1 1 23.4 1 51s22.4 50 50 50 50-22.4 50-50S78.6 1 51 1zm0 98C24.5 99 3 77.5 3 51S24.5 3 51 3s48 21.5 48 48-21.5 48-48 48zm0-23.7c-6.3 0-11.5 5.2-11.5 11.5h2c0-5.2 4.3-9.5 9.5-9.5s9.5 4.3 9.5 9.5h2c0-6.3-5.2-11.5-11.5-11.5zM35.5 37c-2.3-1.1-4.8-.8-6.8.9-.8.6-1.4 1.4-2 2.1-.7.8-1.4 1.6-2.2 2.2-1.4 1-2.9 1.1-4.3.5-1.2-.5-2-1.5-2.5-2.7-.4-1.2-.4-2.5.1-3.6l-1.8-.8c-.8 1.6-.8 3.5-.2 5.2.6 1.7 1.9 3 3.5 3.8 2 .9 4.3.7 6.3-.7 1.1-.7 1.9-1.6 2.6-2.5.6-.7 1.1-1.3 1.8-1.8 1.4-1.1 3-1.3 4.7-.6 1.2.5 2 1.5 2.5 2.7.4 1.2.4 2.5-.1 3.6l1.8.8c.8-1.6.8-3.5.2-5.2-.7-1.8-1.9-3.2-3.6-3.9zm21.3 26.4H44l11.5-33.2-1.9-.7-12.4 35.9h15.6z"
    />
  </svg>
);

export default SadFaceIcon;
