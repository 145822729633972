import styled from '@emotion/styled';
import {
  color,
  display,
  space,
  textStyle,
  typography,
  variant,
  compose,
  DisplayProps,
  SpaceProps,
  TextStyleProps,
  TypographyProps,
} from 'styled-system';

type FontSize = 'small' | 'medium' | 'large' | 'xlarge';

type Props = DisplayProps &
  SpaceProps &
  TextStyleProps &
  TypographyProps & {
    // the size prop maps to the 4 variant font sizes that display text can be
    // as defined by the type scale in our design system
    size?: FontSize | FontSize[];
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  };

export const DisplayText = styled.h2<Props>`
  margin: 0;
  line-height: 1.2;
  ${compose(
    variant({
      prop: 'size',
      variants: {
        small: {
          fontSize: 10, // 48px
        },
        medium: {
          fontSize: 11, // 60px
        },
        large: {
          fontSize: 12, // 72px
        },
        xlarge: {
          fontSize: 13, // 96px
        },
      },
    }),
    color,
    display,
    space,
    textStyle,
    typography
  )};
`;

DisplayText.defaultProps = {
  size: 'small',
};
