import styled from '@emotion/styled';
import {
  border,
  color,
  display,
  space,
  textStyle,
  typography,
  variant,
  compose,
  BorderProps,
  DisplayProps,
  SpaceProps,
  TextStyleProps,
  TypographyProps,
} from 'styled-system';

type FontSize = 'xsmall' | 'small' | 'medium' | 'large';

type Props = BorderProps &
  DisplayProps &
  SpaceProps &
  TextStyleProps &
  TypographyProps & {
    // the size prop maps to the 3 variant font sizes that body text can be
    // as defined by the type scale in our design system
    size?: FontSize | FontSize[];
    as?: 'p' | 'span' | 'time' | 'small';
  };

export const Text = styled.span<Props>`
  line-height: 1.6;
  ${variant({
    prop: 'size',
    variants: {
      xsmall: {
        fontSize: 0, // 11px
      },
      small: {
        fontSize: 3, // 16px
      },
      medium: {
        fontSize: 4, // 18px
      },
      large: {
        fontSize: 5, // 24px
      },
    },
  })}
  ${compose(border, color, display, space, textStyle, typography)};
`;

Text.defaultProps = {
  size: 'small',
};
