/**
 * Environment Utils
 */
import localConfig, { HOSTS } from 'shared/config/config-local';

export enum EnvName {
  Local = 'local',
  Dev = 'dev',
  Preprod = 'preprod',
  Prod = 'prod',
}

export enum Domain {
  Local = 'localhost',
  Dev = 'vscodev',
  Preprod = 'vscostaging',
  Prod = 'vsco',
  Admin = 'vscoadmin',
}

export function getEnv(): EnvName {
  if (typeof window === 'undefined') {
    return (process.env.VSCO as EnvName) || EnvName.Prod;
  }

  const hostnameSegments = getHostnameSegments();
  const [domain] = hostnameSegments.slice(-2);

  // i.e. vsco.co or eng.vscostaging.com
  switch (domain) {
    case Domain.Prod:
      return EnvName.Prod;
    case Domain.Preprod:
      return EnvName.Preprod;
    case Domain.Dev:
      return EnvName.Dev;
    case Domain.Local:
      return EnvName.Local;
    case Domain.Admin:
      return hostnameSegments[1] as EnvName;
    default: {
      // otherwise we know we're on an environment-based subdomain
      // i.e. prod.crt-app.pizza or dev.curator-app.pizza
      const envs: string[] = Object.values(EnvName);
      if (envs.includes(hostnameSegments[0])) {
        return hostnameSegments[0] as EnvName;
      }

      // Fallback to Local for security
      return EnvName.Local as EnvName;
    }
  }
}

export function getDomain(): string {
  return getHostnameSegments().slice(-2)[0];
}

function getHostnameSegments(): string[] {
  return window.location.hostname.split('.');
}

// getResponsiveImageServiceDomain is used when processing image requests for
// an ID (ie: //${RISDomain}/${id}) as opposed to consuming the `responsive_url`
// provided by the APIs
export const getResponsiveImageServiceDomain = (env: string) => {
  switch (env) {
    case EnvName.Prod:
      return 'i.vsco.co';
    case EnvName.Local:
      return getLocalRISDomain();
    case EnvName.Preprod:
      return 'i.vscostaging.com';
    case EnvName.Dev:
      return 'i.vscodev.com';
    default:
      return 'i.vsco.co';
  }
};

export const getLocalRISDomain = () => {
  if (localConfig.proxy === HOSTS.PROD) return 'i.vsco.co';
  if (localConfig.proxy === HOSTS.PREPROD) return 'i.vscostaging.com';
  if (localConfig.proxy === HOSTS.DEV) return 'i.vscodev.com';

  return 'i.vscostaging.com';
};

export const RISDomain = getResponsiveImageServiceDomain(getEnv());

export function getApiRoot(): string {
  switch (process.env.VSCO) {
    case EnvName.Prod:
      return 'https://vsco.co/api';
    case EnvName.Preprod:
      return 'https://vscostaging.com/api';
    case EnvName.Dev:
      return 'https://vscodev.com/api';
    case EnvName.Local:
      return 'http://localhost:8686/api';
    default:
      return 'https://vsco.co/api';
  }
}

export const ENV = getEnv();
export const isLocal = ENV === EnvName.Local;
export const isDev = ENV === EnvName.Dev;
export const isPreprod = ENV === EnvName.Preprod;
export const isProd = ENV === EnvName.Prod;
