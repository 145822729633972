import * as env from '../env';
import * as utils from './utils';

// NOTE: this is temporary and will be removed once the call below is handled by an
// api client
const hostnameMap: Record<env.EnvName, 'vscodev.com' | 'vscostaging.com' | 'vsco.co'> = {
  [env.EnvName.Local]: 'vsco.co',
  [env.EnvName.Dev]: 'vscodev.com',
  [env.EnvName.Preprod]: 'vscostaging.com',
  [env.EnvName.Prod]: 'vsco.co',
};

// TODO: investigate using api client to handle this call
export function trackEvent(eventName: string = '', properties: Object = {}, userId?: number) {
  if (env.isLocal) return;

  const currentEnv = env.ENV;
  const hostname = hostnameMap[currentEnv];
  const request = new Request(`https://${hostname}/api/cantor/track`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(utils.buildPayload(eventName, properties, userId)),
  });

  fetch(request);
}

export function sendCantorPageView(userId: number, sessionId: string = '') {
  const properties = {
    occurred: true,
    sessionId,
  };

  return trackEvent('Page Viewed', properties, userId);
}

// Google Analytics Page View
export function sendGAPageView() {
  if (typeof window === 'undefined') return;
  try {
    // @ts-ignore
    window.ga('send', 'pageview', window.location.pathname);
  } catch (e) {
    // Log error ('Google Analytics is not available');
  }
}
