import styled from '@emotion/styled';
import {
  color,
  display,
  space,
  typography,
  textStyle,
  variant,
  compose,
  DisplayProps,
  SpaceProps,
  TypographyProps,
  TextStyleProps,
} from 'styled-system';

type FontSize = 'small' | 'medium' | 'large' | 'xlarge';

type Props = DisplayProps &
  SpaceProps &
  TextStyleProps &
  TypographyProps & {
    // the size prop maps to the 4 variant font sizes that heading text can be
    // as defined by the type scale in our design system
    size?: FontSize | FontSize[];
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  };

export const HeadingText = styled.h2<Props>`
  margin: 0;
  line-height: 1.2;
  font-weight: 600;
  ${variant({
    prop: 'size',
    variants: {
      small: {
        fontSize: 6, // 28px
      },
      medium: {
        fontSize: 7, // 32px
      },
      large: {
        fontSize: 8, // 36px
      },
      xlarge: {
        fontSize: 9, // 42px
      },
    },
  })};
  ${compose(color, display, space, typography, textStyle)};
`;

HeadingText.defaultProps = {
  size: 'small',
};
