import React, { ReactElement } from 'react';
import { colors } from 'shared/utils';
import Message from './Message';

interface Props {
  error: string | ReactElement;
  color?: string;
  align?: string;
  style?: object;
}

const ErrorMessage = ({ error = '', color = colors.red, align, style = {}, ...props }: Props) => {
  if (!error) return null;

  return (
    <Message
      {...props}
      styleOverrides={{ color, justifyContent: align || null, ...style }}
      message={error}
    />
  );
};

export default ErrorMessage;
