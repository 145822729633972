import React from 'react';
import styled from '@emotion/styled';
import { centerAbsolute } from 'shared/utils/styles';
import { colors } from 'shared/utils/colors';
import { IconVideoControlsPlay, IconVideoControlsPause } from './icons';

const PLAY_PAUSE_BTN_HEIGHT = 60;

interface ShouldHideProp {
  shouldHide: boolean;
}

const PlayPauseButton = styled('button')<ShouldHideProp>`
  position: absolute;
  height: ${PLAY_PAUSE_BTN_HEIGHT}px;
  width: ${PLAY_PAUSE_BTN_HEIGHT}px;
  ${centerAbsolute(PLAY_PAUSE_BTN_HEIGHT)};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.shouldHide ? 0 : 1)};
  transition: opacity 0.5s;
  pointer-events: ${props => (props.shouldHide ? 'none' : 'auto')};
  /* removes the highlight that is triggered on tap */
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  &::before {
    content: '';
    display: block;
    height: 42px;
    width: 42px;
    position: absolute;
    background-color: ${colors.black};
    opacity: 0.2;
    border-radius: ${42 / 2}px;
  }

  svg {
    z-index: 1;
  }
`;

interface Props {
  shouldHide: boolean;
  isPlaying: boolean;
  onClick: () => void;
}

function VideoControlsPlayPause({ shouldHide, isPlaying, onClick }: Props) {
  return (
    <PlayPauseButton shouldHide={shouldHide} onClick={onClick}>
      {isPlaying ? <IconVideoControlsPause /> : <IconVideoControlsPlay />}
    </PlayPauseButton>
  );
}

export default VideoControlsPlayPause;
