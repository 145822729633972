import React from 'react';
import { colors, fonts } from 'shared/utils';

type Props = {
  isActive: boolean;
  index: number;
  label: string;
};

function Tab({ isActive, index, label }: Props) {
  return (
    <button css={[sx.tab, isActive && sx.activeTab]} data-index={index}>
      {label}
    </button>
  );
}

export default Tab;

const sx = {
  tab: {
    background: 'transparent',
    borderBottom: '2px solid transparent',
    color: colors.gray,
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    padding: '0 15px 5px',
    fontSize: 16,
    ...fonts.book,
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      cursor: 'pointer',
      color: `${colors.charcoalLight}`,
      borderBottom: `2px solid ${colors.grayDark}`,
    },
  },
  activeTab: {
    color: colors.black,
    borderBottom: `2px solid ${colors.black}`,
    ...fonts.medium,
  },
  '&:hover': {
    cursor: 'pointer',
    borderBottom: `2px solid ${colors.black}`,
  },
};
