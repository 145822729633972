import React, { useEffect, useRef, MouseEvent, useReducer } from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { hexa } from 'shared/utils/style';
import { zIndex } from 'shared/utils/styles';
import { KeyCode } from 'shared/constants';
import { useLockedBody } from 'shared/hooks';

export enum ModalState {
  INITIAL,
  CLOSED,
  CLOSING,
  OPEN,
  OPENING,
}

enum ActionType {
  START_OPENING,
  END_OPENING,
  START_CLOSING,
  END_CLOSING,
}

interface Action {
  type: ActionType;
}

// Modal can be in 5 possible states:
// - INITIAL: initial state
// - CLOSED: modal is fully hidden from view
// - OPENING: modal is animating towards open position
// - OPEN: modal is fully visible
// - CLOSING: modal is animating towards close position
//
// the actions that trigger state transitions are dispatched by the following effects
// open === true -> START_OPENING
// slideUp animationend event -> END_OPENING
// open === false -> START_CLOSING
// slideDown animationend event -> END_CLOSING
function reducer(state: ModalState, action: Action): ModalState {
  switch (action.type) {
    case ActionType.START_OPENING: {
      // NOTE: we want to transition directly to OPEN when coming from the INITIAL
      // state to avoid triggering the animation
      if (state === ModalState.INITIAL) {
        return ModalState.OPEN;
      } else if (state !== ModalState.CLOSED) {
        return state;
      }

      return ModalState.OPENING;
    }
    case ActionType.END_OPENING: {
      if (state !== ModalState.OPENING) return state;

      return ModalState.OPEN;
    }
    case ActionType.START_CLOSING: {
      // NOTE: we want to transition directly to CLOSED when coming from the INITIAL
      // state to avoid triggering the animation
      if (state === ModalState.INITIAL) {
        return ModalState.CLOSED;
      } else if (state !== ModalState.OPEN) {
        return state;
      }

      return ModalState.CLOSING;
    }
    case ActionType.END_CLOSING: {
      if (state !== ModalState.CLOSING) return state;

      return ModalState.CLOSED;
    }
  }
}

interface Props {
  children: React.ReactNode;
  enableScrollLock?: boolean;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  maxWidth?: number;
  showModalClose?: boolean;
  title?: string;
  shouldOpen?: boolean;
  disableBackdropClick?: boolean;
  onClosed?: () => void;
}

export default function Modal({
  children,
  maxWidth,
  enableScrollLock = false,
  showModalClose = true,
  title,
  shouldOpen = false,
  onClosed,
  isFullWidth = false,
  isFullHeight = false,
  disableBackdropClick = false,
}: Props) {
  const modalOuter = useRef<HTMLDivElement>(null);
  const [modalState, dispatch] = useReducer(reducer, ModalState.INITIAL);
  const [, setLocked] = useLockedBody();

  useEffect(() => {
    shouldOpen
      ? dispatch({ type: ActionType.START_OPENING })
      : dispatch({ type: ActionType.START_CLOSING });
  }, [shouldOpen]);

  useEffect(() => {
    // NOTE: this variable is necessary to allow for the removal of the event handler
    const modalRef = modalOuter.current;

    function handleAnimationEnd(e: AnimationEvent) {
      if (e.animationName === slideUp.name) {
        dispatch({ type: ActionType.END_OPENING });
      } else if (e.animationName === slideDown.name) {
        dispatch({ type: ActionType.END_CLOSING });
        onClosed?.();
      }
    }

    modalRef?.addEventListener('animationend', handleAnimationEnd);

    return () => modalRef?.removeEventListener('animationend', handleAnimationEnd);
  }, [onClosed]);

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.code === KeyCode.ESC) {
        handleClose();
      }
    }

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  useEffect(() => {
    if (enableScrollLock) {
      switch (modalState) {
        case ModalState.OPEN: {
          setLocked(true);
          break;
        }
        case ModalState.CLOSING: {
          setLocked(false);
          break;
        }
      }
    }

    return () => {
      if (enableScrollLock) {
        // Remove scroll lock when unmounted
        setLocked(false);
      }
    };
  }, [enableScrollLock, modalState, setLocked]);

  function handleOuterModalClick(event: MouseEvent<HTMLDivElement>) {
    if (disableBackdropClick) return;

    const outerModal = event.currentTarget;

    // close modal on click outside
    if (event.target === outerModal) handleClose();
  }

  function handleClose() {
    dispatch({ type: ActionType.START_CLOSING });
  }

  return (
    <ModalOuter
      role="dialog"
      modalState={modalState}
      ref={modalOuter}
      onClick={handleOuterModalClick}
      data-testid="modal-outer"
      data-modal-state={modalState}
    >
      <ModalInner isFullWidth={isFullWidth} isFullHeight={isFullHeight} maxWidth={maxWidth}>
        {showModalClose && (
          <ModalClose onClick={handleClose} type="button">
            &times;
          </ModalClose>
        )}

        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalBody>{children}</ModalBody>
      </ModalInner>
    </ModalOuter>
  );
}

const DEFAULT_OPACITY = 0.7;

export const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
`;

export const slideDown = keyframes`
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
`;

interface ModalOuterProps {
  modalState: ModalState;
}

function getAnimationName(modalState: ModalState) {
  if (modalState === ModalState.CLOSING) {
    return slideDown;
  } else if (modalState === ModalState.OPENING) {
    return slideUp;
  } else {
    return 'none';
  }
}

function animationStyles(props: ModalOuterProps) {
  if (props.modalState === ModalState.OPEN) {
    return 'transform: translateY(0)';
  } else if (props.modalState === ModalState.INITIAL || props.modalState === ModalState.CLOSED) {
    return 'transform: translateY(100%)';
  }

  const animationName = getAnimationName(props.modalState);

  return css`
    animation-name: ${animationName};
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  `;
}

export const ModalOuter = styled('div')<ModalOuterProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.MODAL};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => hexa(props.theme.colors.black, DEFAULT_OPACITY)};
  ${animationStyles};
`;

interface ModalInnerProps {
  isFullWidth: boolean;
  isFullHeight: boolean;
  maxWidth?: number;
}

export const ModalInner = styled('div')<ModalInnerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};
  height: ${props => (props.isFullHeight ? '100%' : 'auto')};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : null)};
  margin: ${props => (props.isFullWidth ? null : '16px')};
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => (props.isFullWidth || props.isFullHeight ? null : '3px')};
`;

const CLOSE_BTN_PADDING = 22;
const CLOSE_BTN_HEIGHT = 24; // (38px * 0.65)
export const ModalHeader = styled('div')`
  padding: 20px;
  font-weight: ${props => props.theme.fontWeights.medium};
  border-bottom: 1px solid ${props => props.theme.colors.grayLight};
  height: ${2 * CLOSE_BTN_PADDING + CLOSE_BTN_HEIGHT + 1}px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
`;

export const ModalClose = styled('button')`
  /* ensures that close button is above all other content */
  z-index: ${zIndex.MODAL};
  position: absolute;
  top: 0;
  right: 0;
  padding: ${CLOSE_BTN_PADDING}px;
  border-radius: 50%;
  background-color: ${props => hexa(props.theme.colors.white, 0.7)};
  color: ${props => props.theme.colors.charcoal};
  font-size: 38px;
  line-height: 0.65;
  transition: background-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.white};
  }
`;

export const ModalBody = styled('div')`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
`;
