import React from 'react';
import { css, keyframes } from '@emotion/react';
import { colors } from '../utils';

type Props = {
  size?: number;
  color?: string;
  strokeWidth?: number;
  style?: React.CSSProperties;
};

const Spinner = ({ size = 16, color = colors.grayDark, strokeWidth = 12, style = {} }: Props) => {
  const spin: string = keyframes`
    0% { transform: rotate(0deg); }
    20% { transform: rotate(110deg); }
    100% { transform: rotate(360deg); }
  `;
  const spinnerSx = css`
    display: block;
    margin-left: auto;
    margin-right: auto;
    animation: ${spin} 0.7s cubic-bezier(0.22, 0.61, 0.36, 1) infinite;
  `;
  return (
    <svg
      style={style}
      css={spinnerSx}
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      role="status"
    >
      <circle
        stroke={color}
        cx="50"
        cy="50"
        r="43"
        strokeDasharray="213.62830044410595 53.40707511102647"
        fill="none"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Spinner;
