import React, { useState } from 'react';
import useIsomorphicLayoutEffect from 'shared/hooks/useIsomorphicEffect';
import { createPortal } from 'react-dom';

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

type Props = {
  children: React.ReactNode;
  wrapperId: string;
};
function ReactPortal({ children, wrapperId }: Props) {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useIsomorphicLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let isCreated = false;
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId);
      isCreated = true;
    }

    setWrapperElement(element);

    return () => {
      // delete the created element
      if (isCreated && element?.parentNode) {
        element?.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // wrapperElement state will be null on very first render
  if (!wrapperElement) return null;

  return createPortal(children, wrapperElement);
}

export default ReactPortal;
