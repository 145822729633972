import React from 'react';
import { colors } from 'shared/utils';

interface Props {
  width?: number;
  height?: number;
}

function IconVideoControlsPause({ width = 40, height = 40 }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
    >
      <g stroke={colors.white} fill="none" fillRule="evenodd">
        <circle cx="19" cy="19" r="19" strokeWidth="2" transform="translate(1 1)" />
        <path d="M16.5 14v12M23.5 14v12" strokeWidth="3" strokeLinecap="square" />
      </g>
    </svg>
  );
}

export default IconVideoControlsPause;
