import React from 'react';
import styled from '@emotion/styled';
import { colors } from 'shared/utils/colors';
import { fonts } from 'shared/utils/fonts';
import IconVideoSadFace from './icons/IconVideoSadFace';

const Container = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.eggWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin-bottom: 14px;
  }
`;

const ErrorMessage = styled('p')`
  margin: 0;
  ${fonts.book};
  color: ${colors.gray};
  font-size: 14px;
  line-height: 17px;
`;

interface Props {
  message: string;
}

function VideoError({ message }: Props) {
  return (
    <Container>
      <IconVideoSadFace />
      <ErrorMessage>{message}</ErrorMessage>
    </Container>
  );
}

export default VideoError;
