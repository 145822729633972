import { css } from '@emotion/react';
import { colors, fonts } from 'shared/utils';
import { hexa } from 'shared/utils/style';

export default css`
  /**
 * Force padding and border inside the box.
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 * \`input[type="search"]\` overrides normalize.css more specific rule.
 */
  *,
  *::before,
  *::after,
  input[type='search'] {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    height: 100%; /* needed for sticky footer */
    font-size: 16px;
  }

  html,
  body,
  #root {
    min-height: 100%;
    margin: 0;
  }

  body {
    color: ${colors.charcoalDark};
    ${fonts.book}
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    line-height: 1.2;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    color: currentColor;
    text-decoration: inherit;
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  p a {
    text-decoration: underline;
    text-decoration-skip: ink;
  }

  button {
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  ::-moz-selection {
    background: ${hexa(colors.royalBlue, 0.5)};
  }

  ::selection {
    background: ${hexa(colors.royalBlue, 0.5)};
  }
`;
