export enum PageSize {
  PERSONAL_MEDIA = 14,
  COLLECTION_MEDIA = 20,
  FOLLOWS = 10,
  SEARCH_PEOPLE_MEDIA = 2,
  CURATION_MEDIA = 15,
  ARTICLE_MEDIA = 12,
}

export enum ReqStatus {
  IDLE,
  LOADING,
  SUCCEEDED,
  FAILED,
}
