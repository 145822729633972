import { ReasonLabel } from 'shared/constants';

export const SAFETY_REASONS = [
  ReasonLabel.SELF_HARM_OR_SUICIDAL,
  ReasonLabel.BULLYING_OR_HARASSMENT,
  ReasonLabel.VIOLENT_THREAT,
  ReasonLabel.ANIMAL_ABUSE,
  ReasonLabel.DEATH_OR_SEVERE_INJURY,
];

export enum UIState {
  MENU_MAIN,
  MENU_INAPPROPRIATE,
  MENU_SAFETY,
  MENU_MISLEADING,
  MENU_NSE,
  MENU_HATE,
  MENU_ETH,
  MENU_ILLEGAL,
  MENU_SELF_HARM,
  MENU_BULLYING,
  MENU_VIOLENCE,
  MENU_SPAM,
  MENU_FALSE_INFO,
  SUCCESS,
  ERROR,
}

export interface MenuData {
  heading?: string;
  menuItems?: MenuItem[];
  reasons?: ReasonLabel[];
  guidelines?: GuidelineData[];
  parent?: UIState;
}

export interface MenuItem {
  label: string;
  to: UIState;
}

export interface GuidelineData {
  copy: string;
  items?: string[];
}

export const menuDataList: { [idx: number]: MenuData } = {
  [UIState.MENU_MAIN]: {
    menuItems: [
      {
        label: "It's inappropriate",
        to: UIState.MENU_INAPPROPRIATE,
      },
      {
        label: "I'm worried about someone's safety",
        to: UIState.MENU_SAFETY,
      },
      {
        label: "It's misleading",
        to: UIState.MENU_MISLEADING,
      },
    ],
  },
  // Level 1 submenus
  [UIState.MENU_INAPPROPRIATE]: {
    heading: "It's inappropriate",
    menuItems: [
      {
        label: 'Nudity or sexually explicit',
        to: UIState.MENU_NSE,
      },
      {
        label: 'Hateful conduct',
        to: UIState.MENU_HATE,
      },
      {
        label: 'Extremist/Terrorist/Hate Organization',
        to: UIState.MENU_ETH,
      },
      {
        label: 'Illegal behavior',
        to: UIState.MENU_ILLEGAL,
      },
    ],
    parent: UIState.MENU_MAIN,
  },
  [UIState.MENU_SAFETY]: {
    heading: "I'm worried about someone's safety",
    menuItems: [
      {
        label: 'Self harm or suicidal',
        to: UIState.MENU_SELF_HARM,
      },
      {
        label: 'Bullying or harassment',
        to: UIState.MENU_BULLYING,
      },
      {
        label: 'Violence and threats',
        to: UIState.MENU_VIOLENCE,
      },
    ],
    parent: UIState.MENU_MAIN,
  },
  [UIState.MENU_MISLEADING]: {
    heading: "It's misleading",
    menuItems: [
      {
        label: "It's spam",
        to: UIState.MENU_SPAM,
      },
      {
        label: 'False information',
        to: UIState.MENU_FALSE_INFO,
      },
    ],
    parent: UIState.MENU_MAIN,
  },
  // Submenus for MENU_INAPPROPRIATE
  [UIState.MENU_NSE]: {
    heading: 'Nudity or Sexually Explicit',
    reasons: [
      ReasonLabel.NUDITY_OR_PORNOGRAPHIC,
      ReasonLabel.SEXUAL_EXPLOITATION,
      ReasonLabel.INVOLVES_A_MINOR,
    ],
    guidelines: [
      {
        copy:
          'Sexually explicit or pornographic content, including digitally-created content that depicts things like',
        items: [
          'Sexual intercourse',
          'Masturbation',
          'Genitals',
          'Zoomed in, unclothed buttocks',
          'Frontal nudity',
        ],
      },
      {
        copy:
          'Any nude content involving minors or content that appears to be sexualizing a minor will be removed and promptly reported to the proper authorities.',
      },
    ],
    parent: UIState.MENU_INAPPROPRIATE,
  },
  [UIState.MENU_HATE]: {
    heading: 'Hateful Conduct',
    reasons: [ReasonLabel.HATEFUL_CONDUCT],
    guidelines: [
      {
        copy:
          'Discriminating or promoting hatred or violence towards individuals or groups based on: ',
        items: [
          'Race',
          'Ethnicity',
          'National origin',
          'Religious affiliation',
          'Gender identity',
          'Sexual orientation',
          'Age',
          'Disability or diseases',
        ],
      },
    ],
    parent: UIState.MENU_INAPPROPRIATE,
  },
  [UIState.MENU_ETH]: {
    heading: 'Extremist/Terrorist/Hate Organization',
    reasons: [ReasonLabel.EXTREMIST_TERRORIST_HATE_ORGANIZATION],
    guidelines: [
      {
        copy:
          'Hate or extremist organizations with a record and clearly-stated intention to commit terrorist or violent criminal activity are prohibited from maintaining a presence on VSCO to promote any of their campaigns, plans, celebrate their violent acts, to fundraise, or recruit people.',
      },
    ],
    parent: UIState.MENU_INAPPROPRIATE,
  },
  [UIState.MENU_ILLEGAL]: {
    heading: 'Illegal Behavior',
    reasons: [
      ReasonLabel.SOLICITING_SEXUAL_SERVICES,
      ReasonLabel.FRAUDULENT_ACTIVITIES,
      ReasonLabel.ILLEGAL_OR_BANNED_SUBSTANCES,
    ],
    guidelines: [
      {
        copy: 'Content engaging in illegal behavior or criminal activity, including things like:',
        items: [
          'Illegal drugs or other banned substances',
          'Sexual services',
          'Fraudulent activities',
          'Engaging or trying to engage a minor in conversation for sexual purposes',
          'Anything else that is against the law',
        ],
      },
    ],
    parent: UIState.MENU_INAPPROPRIATE,
  },
  // Submenus for MENU_SAFETY
  [UIState.MENU_SELF_HARM]: {
    heading: 'Self Harm or Suicidal',
    reasons: [ReasonLabel.SELF_HARM_OR_SUICIDAL],
    guidelines: [
      {
        copy:
          'Promoting behavior intended to place minors at serious risk of harm (e.g. daring challenge games)',
      },
      {
        copy: 'Graphic content that glorifies self-harm such as things like:',
        items: ['Eating disorders', 'Cutting', 'Suicide'],
      },
      {
        copy: 'Encouraging or telling another person to commit suicide or engage in self-harm',
      },
    ],
    parent: UIState.MENU_SAFETY,
  },
  [UIState.MENU_BULLYING]: {
    heading: 'Bullying or Harassment',
    reasons: [ReasonLabel.BULLYING_OR_HARASSMENT],
    guidelines: [
      {
        copy: 'Degrading, shaming, or the harassment of others',
      },
    ],
    parent: UIState.MENU_SAFETY,
  },
  [UIState.MENU_VIOLENCE]: {
    heading: 'Violence and Threats',
    reasons: [
      ReasonLabel.VIOLENT_THREAT,
      ReasonLabel.ANIMAL_ABUSE,
      ReasonLabel.DEATH_OR_SEVERE_INJURY,
    ],
    guidelines: [
      {
        copy: 'Horrifying, violent, or distressing content, including animal abuse',
      },
      {
        copy: 'Threatening to cause physical, emotional, or financial harm towards others',
      },
    ],
    parent: UIState.MENU_SAFETY,
  },
  // Submenus for MENU_MISLEADING
  [UIState.MENU_SPAM]: {
    heading: "It's Spam",
    reasons: [ReasonLabel.SPAM],
    guidelines: [
      {
        copy: 'Spammy or misleading content',
      },
      {
        copy: 'Content intended to bother or harass others on VSCO',
      },
    ],
    parent: UIState.MENU_MISLEADING,
  },
  [UIState.MENU_FALSE_INFO]: {
    heading: 'False Information',
    reasons: [ReasonLabel.FALSE_INFORMATION],
    parent: UIState.MENU_MISLEADING,
  },
};
