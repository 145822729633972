export enum HOSTS {
  DEV = 'https://vscodev.com',
  PREPROD = 'https://vscostaging.com',
  PROD = 'https://vsco.co',
}

export interface LocalConfig {
  showDebug: boolean;
  proxy: HOSTS;
  rosco: {
    host: string;
  };
  cookie: {
    httpOnly: boolean;
    secure: boolean;
    sameSite: boolean;
  };
  vsco: {
    protocol: string;
    domain: string;
    host: string;
  };
}

const localConfig: LocalConfig = {
  showDebug: true,
  proxy: HOSTS.PREPROD,

  rosco: {
    host: '0.0.0.0',
  },

  cookie: {
    httpOnly: false,
    secure: false,
    sameSite: false,
  },

  vsco: {
    protocol: 'http',
    domain: 'localhost:',
    host: 'vscostaging.com',
  },
};

export default localConfig;
