/**
 * Shared constants manifest
 */

import * as errors from './errors';
export { errors };

import * as EXPERIMENTS from './experiments';
export { EXPERIMENTS };

import * as EVENTS from './events';
export { EVENTS };

export const ASSETS_URL = '//assets.vsco.co/assets';
export const MEDIA_READ_TKN = '7356455548d0a1d886db010883388d08be84d0c9';
export const COOKIE_SESSION = 'vs';
export const COOKIE_ANON_SESSION = 'vs_anonymous_id';
export const COOKIE_APP_ID = 'vs_app_id';

export const CONTAINER_WIDTH_PERCENTAGE = 0.88;

// for reference: https://github.com/mixer/uuid-validate/blob/master/index.js#L2
export const UUID_V4_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export * from './api';
export * from './dcdrFlags';
export * from './report';
export * from './vsco';
export * from './keyCodes';
export * from './spaces';
