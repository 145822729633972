/**
 * Device Helpers
 * Helpers for determining user device specs
 */

// UA String util
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile_Tablet_or_Desktop
function uaContains(regex: RegExp): boolean {
  return regex.test(window.navigator.userAgent);
}

export function isTouchDevice(): boolean {
  if (isServer()) return false;
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export function maxDeviceWidth(width: number): boolean {
  return window.matchMedia(`(max-device-width: ${width}px)`).matches;
}

export function viewportWidth(): number {
  const clientWidth = document.documentElement ? document.documentElement.clientWidth : 0;
  return Math.max(clientWidth, window.innerWidth || 0);
}

export function viewportHeight(): number {
  const clientHeight = document.documentElement ? document.documentElement.clientHeight : 0;
  return Math.max(clientHeight, window.innerHeight || 0);
}

export function isElementInViewport(el: Element, threshold: number = 300) {
  const rect = el.getBoundingClientRect();
  const aboveTheViewport = rect.top + threshold <= 0;
  const leftOfViewport = rect.left + threshold <= 0;
  const belowTheViewport = rect.bottom - threshold >= (window.innerHeight || viewportHeight());
  const rightOfViewport = rect.right - threshold >= (window.innerWidth || viewportWidth());

  return !aboveTheViewport && !leftOfViewport && !belowTheViewport && !rightOfViewport;
}

export function isRetina(): boolean {
  return window.devicePixelRatio >= 1.5;
}

export function isPhoneOrTablet(): boolean {
  return isTouchDevice() && maxDeviceWidth(1024);
}

export function isServer(): boolean {
  return typeof window === 'undefined';
}

export function isClient(): boolean {
  return !isServer();
}

export function isAndroid(): boolean {
  return /(android)/i.test(navigator.userAgent);
}

// returns truthy if the current browser is an iOS webview.
export function isIOSWebView(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios && !safari;
}

export function isMobile(): boolean {
  return isTouchDevice() && uaContains(/Mobi/);
}

export function isTablet(): boolean {
  return isTouchDevice() && (uaContains(/iPad/) || uaContains(/Tablet/));
}

export function isDesktop(): boolean {
  return !isMobile() && !isTablet() && (uaContains(/Windows NT/) || uaContains(/Macintosh/));
}

export function isIOS(): boolean {
  return uaContains(/iPhone/) || uaContains(/iPad/);
}

export function isMacOS(): boolean {
  return uaContains(/Macintosh/);
}

export function isWindows(): boolean {
  return uaContains(/Windows/);
}

export function isSafari(): boolean {
  return uaContains(/Macintosh.+Safari/) && !uaContains(/Chrome\//) && !uaContains(/Edg(?:e)?\//);
}
