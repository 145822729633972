import React from 'react';
import { colors } from 'shared/utils';
import styled from '@emotion/styled';

type Props = {
  tip: string;
  hide: boolean;
};

const InputTip = ({ tip, hide }: Props) => {
  return <SxInputTip hide={hide}>{tip}</SxInputTip>;
};

const SxInputTip = styled('div')<{ hide: boolean }>`
  margin-bottom: 10px;
  color: ${colors.grayDark};
  font-size: 14px;
  margin-top: ${props => (props.hide ? '0' : '-4px')};
`;

export default InputTip;
