import React from 'react';
import styled from '@emotion/styled';
import { miniButtonBase, Spacer } from './VideoControls.css';
import { IconVideoControlsMute, IconVideoControlsUnMute } from './icons';

const MuteUnmuteButton = styled('button')`
  ${miniButtonBase};
`;

interface Props {
  hasAudio: boolean;
  isMuted: boolean;
  onClick: () => void;
}

function VideoControlsMuteUnMute({ hasAudio, isMuted, onClick }: Props) {
  return (
    <>
      {hasAudio ? (
        <MuteUnmuteButton onClick={onClick}>
          {isMuted ? <IconVideoControlsMute /> : <IconVideoControlsUnMute />}
        </MuteUnmuteButton>
      ) : (
        <Spacer />
      )}
    </>
  );
}

export default VideoControlsMuteUnMute;
