// TODO: moved to shared components since CRT could  use

import React, { ChangeEvent, useState } from 'react';
import { colors } from 'shared/utils';
import { Input } from 'shared/components';
import { ShowPasswordIcon } from 'shared/components/icons';

type Props = {
  isInvalid: boolean;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

enum TogglePassword {
  TEXT = 'text',
  PASSWORD = 'password',
}

function ShowPassword({ isInvalid, value, onChange }: Props) {
  const [showPassword, setShowPassword] = useState(false);

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <div style={{ position: 'relative' }}>
      <Input
        bordered
        placeholder="Password"
        name="password"
        label="Password"
        hideLabel
        type={showPassword ? TogglePassword.TEXT : TogglePassword.PASSWORD}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
      />

      <span
        onClick={toggleShowPassword}
        role="switch"
        aria-checked={showPassword}
        style={{ position: 'absolute', top: 15, right: 10, cursor: 'pointer' }}
      >
        <ShowPasswordIcon fill={showPassword ? colors.black : colors.gray} />
      </span>
    </div>
  );
}

export default ShowPassword;
