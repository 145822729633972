import React, { SyntheticEvent, useState } from 'react';
import { isProd } from '../utils/env';
import { fadeIn } from '../utils/animations';
import { css } from '@emotion/react';

type Props = {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  style?: { [key: string]: string | number };
};

function disableRightClick(e: SyntheticEvent) {
  if (isProd) {
    e.preventDefault();
    return false;
  }
  return true;
}

// TODO: replace img with placeholder element/img when load error occurs
// TODO: borrow ideas from next/Image component
// https://github.com/vercel/next.js/blob/canary/packages/next/client/future/image.tsx
const Image = ({ src, alt = '', style = {}, height, width, ...props }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const fade = isLoaded ? fadeIn : {};

  return (
    <img
      {...props}
      onLoad={() => {
        setIsLoaded(true);
      }}
      onContextMenu={disableRightClick}
      onDragStart={disableRightClick}
      src={src}
      alt={alt}
      height={height}
      width={width}
      css={theme => css`
        display: block;
        background-color: ${isLoaded ? 'transparent' : theme.colors.gray35};
        -webkit-touch-callout: none;
        user-select: none;
        pointer-events: none;
        ${fade};
        ${style};
      `}
    />
  );
};

export default Image;
