import React from 'react';
import { colors } from 'shared/utils';

interface Props {
  width?: number;
  height?: number;
}

function IconVideoControlsExpand({ width = 18, height = 18 }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M20 21H-4V-3h24z" />
        <g stroke={colors.white} strokeWidth="2">
          <path d="M1 5h11v11H1z" />
          <path strokeLinecap="square" d="M11 1h5v5" />
        </g>
      </g>
    </svg>
  );
}

export default IconVideoControlsExpand;
