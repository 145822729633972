import React, { ReactElement } from 'react';
import { css } from '@emotion/react';

interface Props {
  message: string | ReactElement;
  styleOverrides?: { [key: string]: any };
  className?: string;
}

const Message = ({ message = '', styleOverrides = {}, className, ...props }: Props) => {
  if (!message) return null;

  const sx = css`
    display: flex;
    margin-bottom: 20px;
    font-size: 0.8em;
    ${styleOverrides};
  `;

  return (
    <p {...props} className={className} css={sx}>
      {message}
    </p>
  );
};

export default Message;
