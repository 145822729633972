import { invert } from 'lodash';

export enum ReportMediaType {
  UNKNOWN = 0,
  IMAGE = 1,
  DSCO = 2,
  ARTICLE = 3,
  MESSAGE = 4,
  USER = 5,
  VIDEO = 6,
  SPACE = 7,
  SPACE_POST = 8,
  SPACE_POST_COMMENT = 9,
  SPACE_CONTRIBUTOR = 10,
}

export const REPORT_MEDIA_TYPE_NAME = {
  [ReportMediaType.UNKNOWN]: 'Unknown',
  [ReportMediaType.IMAGE]: 'Image',
  [ReportMediaType.DSCO]: 'DSCO',
  [ReportMediaType.ARTICLE]: 'Journal',
  [ReportMediaType.MESSAGE]: 'Message',
  [ReportMediaType.USER]: 'User',
  [ReportMediaType.VIDEO]: 'Video',
  [ReportMediaType.SPACE]: 'Space',
  [ReportMediaType.SPACE_POST]: 'Post',
  [ReportMediaType.SPACE_POST_COMMENT]: 'Response',
  [ReportMediaType.SPACE_CONTRIBUTOR]: 'Contributor',
};

export enum ReportStatus {
  OPEN = 1,
  IGNORE = 2,
  PRUNED = 95,
  DELETE = 100,
  SENT_TO_NCMEC = 101,
  DELETE_ARTICLE_MEDIA = 102,
  USER_SUSPENDED = 103,
}

export const REPORT_STATUS_TO_TEXT = {
  [ReportStatus.OPEN]: 'OPEN',
  [ReportStatus.IGNORE]: 'IGNORE',
  [ReportStatus.PRUNED]: 'PRUNED',
  [ReportStatus.DELETE]: 'DELETE',
  [ReportStatus.SENT_TO_NCMEC]: 'SENT_TO_NCMEC',
  [ReportStatus.DELETE_ARTICLE_MEDIA]: 'DELETE_ARTICLE_MEDIA',
  [ReportStatus.USER_SUSPENDED]: 'USER_SUSPENDED',
};

// https://github.com/vsco/godel/blob/master/protobufs/protos/report/report.proto#L23
export enum Reason {
  UNKNOWN = 0,
  // NOTE: NUDITY, VIOLENCE, HARASSMENT, THREAT, DRUGS, SELF_HARM, SUICIDE, CSAM
  // and SOMETHING_ELSE will be deprecated in the near future
  NUDITY = 1,
  VIOLENCE = 2,
  HARASSMENT = 3,
  THREAT = 4,
  DRUGS = 5,
  SELF_HARM = 6,
  SUICIDE = 7,
  CSAM = 10,
  SPAM = 11,
  NUDITY_OR_PORNOGRAPHIC = 13,
  SEXUAL_EXPLOITATION = 14,
  INVOLVES_A_MINOR = 15,
  HATEFUL_CONDUCT = 16,
  EXTREMIST_TERRORIST_HATE_ORGANIZATION = 17,
  SOLICITING_SEXUAL_SERVICES = 18,
  FRAUDULENT_ACTIVITIES = 19,
  ILLEGAL_OR_BANNED_SUBSTANCES = 20,
  SELF_HARM_OR_SUICIDAL = 21,
  BULLYING_OR_HARASSMENT = 22,
  VIOLENT_THREAT = 23,
  ANIMAL_ABUSE = 24,
  DEATH_OR_SEVERE_INJURY = 25,
  FALSE_INFORMATION = 26,
  IMMINENT_RISK = 27,

  SOMETHING_ELSE = 500,
}

export enum ReasonLabel {
  UNKNOWN = '',
  NUDITY = 'Nudity or sexually explicit content',
  VIOLENCE = 'Violence or gore',
  HARASSMENT = 'Harassment, bullying, or hate speech',
  THREAT = 'Threat of violence or harm',
  DRUGS = 'Illegal drugs or firearms',
  SELF_HARM = 'Self-harm',
  SUICIDE = 'Suicide',
  CSAM = 'CSAM',
  SPAM = "It's spam",
  NUDITY_OR_PORNOGRAPHIC = 'Nudity or pornographic',
  SEXUAL_EXPLOITATION = 'Sexual exploitation',
  INVOLVES_A_MINOR = 'Involves a minor',
  HATEFUL_CONDUCT = 'Hateful conduct',
  EXTREMIST_TERRORIST_HATE_ORGANIZATION = 'Extremist/Terrorist/Hate organization',
  SOLICITING_SEXUAL_SERVICES = 'Soliciting sexual services',
  FRAUDULENT_ACTIVITIES = 'Fraudulent activities',
  ILLEGAL_OR_BANNED_SUBSTANCES = 'Illegal or banned substances',
  SELF_HARM_OR_SUICIDAL = 'Self harm or suicidal',
  BULLYING_OR_HARASSMENT = 'Bullying or harassment',
  VIOLENT_THREAT = 'Violent threat',
  ANIMAL_ABUSE = 'Animal abuse',
  DEATH_OR_SEVERE_INJURY = 'Death or severe injury',
  FALSE_INFORMATION = 'False information',
  IMMINENT_RISK = 'Imminent risk',
  SOMETHING_ELSE = 'Something else',
}

type ReasonKeys = keyof typeof Reason;
const reversedReason = invert(Reason);
const reversedReasonLabel = invert(ReasonLabel);

export function toReasonLabel(reason: number): ReasonLabel {
  const key = reversedReason[reason] as ReasonKeys;
  return ReasonLabel[key] ?? ReasonLabel.UNKNOWN;
}

export function toReasonEnum(label: string): Reason {
  const key = reversedReasonLabel[label] as ReasonKeys;
  return Reason[key] ?? Reason.UNKNOWN;
}

export enum ReportLogEventType {
  UNKNOWN = 0,
  CREATED = 1,
  STATUS_UPDATED = 2,
  REASON_UPDATED = 3,
  ESCALATION_UPDATED = 4,
  ADMIN_UPDATED = 5,
  NOTE_ADDED = 6,
  MEDIA_ADDED = 7,
  SPACE_CONTRIBUTOR_REMOVED = 8,
}

export enum EscalationLevel {
  UNKNOWN = 0,
  // Tier 1 offense
  ZERO = 1,

  // Tier 2 offense
  ONE = 2,

  // Tier 3 offense
  TWO = 3,
}

export enum ReportCategory {
  NSE,
  VIOLENCE,
  ABUSE,
  SELF_HARM,
  UNKNOWN,
  CSAM,
}

export type ReportCategoryKeys = keyof typeof ReportCategory;

// Errors
export enum ERRORS {
  REPORT_NOT_FOUND = 'report_not_found',
}

// ArticleContext is used by the ReviewArticle component in CRT to determine
// the context it's being rendering in. Depending on the context, ReviewArticle
// will show/hide an article's cover image as well as appropriately set
// selected media's 'isWithinArticle' and 'articleContext' fields
export enum ArticleContext {
  CONTENT_PANE,
  USER_JOURNAL,
  LOOKUP,
}
