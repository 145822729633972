import React, { createContext, Dispatch, ReactNode, useContext, useState } from 'react';

interface SelectedVideoPlayerContext {
  selectedMediaId: string;
  setSelectedMediaId?: Dispatch<React.SetStateAction<string>>;
}

interface Props {
  children: ReactNode;
}

const SelectedVideoPlayerContext = createContext<SelectedVideoPlayerContext>({
  selectedMediaId: '',
});

export function SelectedVideoPlayerProvider({ children }: Props) {
  const [selectedMediaId, setSelectedMediaId] = useState('');
  const contextValue = { selectedMediaId, setSelectedMediaId };

  return (
    <SelectedVideoPlayerContext.Provider value={contextValue}>
      {children}
    </SelectedVideoPlayerContext.Provider>
  );
}

export function useSelectedVideoPlayer() {
  return useContext(SelectedVideoPlayerContext);
}
