/**
 * Error constants
 */

export const EXPIRED_TOKEN = 'expired_token';
export const SESSION_EXPIRED = 'session_expired';
export const SESSION_NOT_FOUND = 'session_not_found';
export const EMAIL_REQUIRED = 'email_required';
export const EMAIL_INVALID = 'email_invalid';
export const SITE_NOT_FOUND = 'site_not_found';
export const COMP_CODE_NOT_FOUND = 'comp_code_not_found';
export const COMP_CODE_REDEEMED = 'comp_code_redeemed';
export const COMP_CODE_EXPIRED = 'comp_code_expired';
export const SUBSCRIPTION_ALREADY_CLAIMED = 'subscription_already_claimed';

export default {
  [EXPIRED_TOKEN]: 'Your token has expired.',
  [SESSION_EXPIRED]: 'Your session has expired.',

  [EMAIL_REQUIRED]: 'Email is required.',
  [EMAIL_INVALID]: 'Email is invalid.',

  [SITE_NOT_FOUND]: 'We couldn’t find that username. Try checking for typos before you continue.',
  [COMP_CODE_NOT_FOUND]: 'This code’s incorrect. Try checking for typos before you continue.',
  [COMP_CODE_REDEEMED]:
    'This code’s already been redeemed. Check your code for typos or, if you think this is a mistake, visit our Help Center.',
  [COMP_CODE_EXPIRED]:
    'This code’s expired. Check your code for typos or, if you think this is a mistake, visit our Help Center.',
  [SUBSCRIPTION_ALREADY_CLAIMED]:
    'Unfortunately, we couldn’t apply this offer code since you’re already a part of our membership community.',
};
