import React, { useEffect, useRef, useReducer, useCallback } from 'react';
import { debounce, throttle } from 'lodash';
import { KeyCode } from 'shared/constants';
import { isElementInViewport, isTouchDevice } from 'shared/utils/device';
import { useActiveVideoPlayer, useSelectedVideoPlayer } from 'shared/contexts';
import { isSrcHls, toMinutes } from 'shared/utils/video';
import VideoControlsSpinner from './VideoControlsSpinner';
import VideoControlsPlayPause from './VideoControlsPlayPause';
import VideoControlsMuteUnMute from './VideoControlsMuteUnMute';
import { VideoControlsMode } from './Video';
import { IconVideoControlsExpand } from './icons';

import {
  Container,
  MiniControlsContainer,
  ProgressBarPlaceholder,
  DurationExpandContainer,
  TimeRemaining,
  ExpandButton,
} from './VideoControls.css';

enum PlayerState {
  READY,
  LOADING,
  PLAYING,
  PAUSED,
  WAITING,
}

enum ActionType {
  START_LOAD,
  PLAY,
  PAUSE,
  WAITING,
  RESET,
  HIDE_CONTROLS,
  SHOW_CONTROLS,
  TOGGLE_MUTE,
  TIME_UPDATE,
}

// mimics HTMLMediaElement's readyState enum: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
enum ReadyState {
  HAVE_NOTHING,
  HAVE_METADATA,
  HAVE_CURRENT_DATA,
  HAVE_FUTURE_DATA,
  HAVE_ENOUGH_DATA,
}

interface State {
  playerState: PlayerState;
  isMuted: boolean;
  shouldHideControls: boolean;
  timeRemaining?: string;
}

interface Action {
  type: ActionType;
  timeRemaining?: string;
}

const initialState: State = {
  playerState: PlayerState.READY,
  isMuted: true,
  shouldHideControls: false,
  timeRemaining: '',
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.START_LOAD: {
      if (state.playerState === PlayerState.LOADING) return state;

      return {
        ...state,
        playerState: PlayerState.LOADING,
      };
    }
    case ActionType.PLAY: {
      if (state.playerState === PlayerState.PLAYING) return state;

      return {
        ...state,
        playerState: PlayerState.PLAYING,
      };
    }
    case ActionType.PAUSE: {
      if (state.playerState === PlayerState.PAUSED) return state;

      return {
        ...state,
        playerState: PlayerState.PAUSED,
        shouldHideControls: false,
      };
    }
    case ActionType.HIDE_CONTROLS: {
      if (state.playerState !== PlayerState.PLAYING || state.shouldHideControls) return state;

      return {
        ...state,
        shouldHideControls: true,
      };
    }
    case ActionType.SHOW_CONTROLS: {
      if (state.playerState === PlayerState.PLAYING && state.shouldHideControls) {
        return {
          ...state,
          shouldHideControls: false,
        };
      }
      return state;
    }
    case ActionType.WAITING: {
      if (state.playerState === PlayerState.WAITING) return state;

      return {
        ...state,
        playerState: PlayerState.WAITING,
      };
    }
    case ActionType.TOGGLE_MUTE: {
      return {
        ...state,
        isMuted: !state.isMuted,
      };
    }
    case ActionType.TIME_UPDATE: {
      return {
        ...state,
        timeRemaining: action.timeRemaining,
      };
    }
    case ActionType.RESET: {
      return initialState;
    }
    default:
      throw new Error('[VideoControls] reducer received an action it did not understand');
  }
}

export interface VideoControlsEvent {
  type: 'sound_on' | 'sound_off';
}

interface Props {
  duration?: number;
  hasAudio: boolean;
  mode?: VideoControlsMode;
  src: string;
  mediaId?: string;
  getPlayer: () => HTMLVideoElement | null;
  getHls: () => Hls | null;
  onExpand?: () => void;
  onSoundOn: (evt: VideoControlsEvent) => void;
  onSoundOff: (evt: VideoControlsEvent) => void;
}

function VideoControls({
  duration,
  hasAudio,
  mode = VideoControlsMode.Default,
  src,
  mediaId = '',
  getPlayer,
  getHls,
  onExpand,
  onSoundOn,
  onSoundOff,
}: Props) {
  const [{ isMuted, playerState, shouldHideControls, timeRemaining }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const fadeOutTimerId = useRef(0);

  // NOTE: we use the useActiveVideoPlayer hook to ensure that only one video player is
  // active (playing) at any given time. When the play button is tapped we use setActiveMediaId
  // to update the activeMediaId context value that is declared at the top of the component tree.
  // there is also an effect below that is triggered whenever the activeMediaId value is updated.
  // this effect compares the activeMediaId value with the player's mediaId prop and will pause
  // the player when there is a mismatch.
  const { activeMediaId, setActiveMediaId } = useActiveVideoPlayer();
  const { selectedMediaId } = useSelectedVideoPlayer();

  useEffect(() => {
    const player = getPlayer();

    // resets playerState to READY when player's readyState is 0
    if (player?.readyState === ReadyState.HAVE_NOTHING) {
      dispatch({ type: ActionType.RESET });
    }

    function handlePlay() {
      if (setActiveMediaId) {
        setActiveMediaId(mediaId);
      }

      if (player?.readyState === ReadyState.HAVE_NOTHING) {
        dispatch({ type: ActionType.START_LOAD });
        return;
      }

      dispatch({ type: ActionType.PLAY });
    }

    function handlePause() {
      dispatch({ type: ActionType.PAUSE });
    }

    function handleWaiting() {
      // NOTE: i noticed that the 'waiting' event is fired once the current time returns to 0 (after loop).
      // we don't want to dispatch the WAITING action when this occurs
      if (player?.currentTime === 0) return;

      dispatch({ type: ActionType.WAITING });
    }

    function handlePlaying() {
      dispatch({ type: ActionType.PLAY });
    }

    const handleTimeUpdate = throttle(() => {
      if (!duration) return;

      const timeRemaining = toMinutes(duration - (player?.currentTime || 0));
      dispatch({ type: ActionType.TIME_UPDATE, timeRemaining });
    }, 1000);

    player?.addEventListener('play', handlePlay);
    player?.addEventListener('pause', handlePause);
    player?.addEventListener('waiting', handleWaiting);
    player?.addEventListener('playing', handlePlaying);
    player?.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      player?.removeEventListener('play', handlePlay);
      player?.removeEventListener('pause', handlePause);
      player?.removeEventListener('waiting', handleWaiting);
      player?.removeEventListener('playing', handlePlaying);
      handleTimeUpdate.cancel();
      player?.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [getPlayer, duration, mediaId, setActiveMediaId]);

  // NOTE: responsible for ensuring that only one video player is active and any given time
  useEffect(() => {
    if (!activeMediaId) return;

    const player = getPlayer();
    if (activeMediaId !== mediaId && playerState === PlayerState.PLAYING) {
      player?.pause();
    }
  }, [activeMediaId, mediaId, playerState, getPlayer]);

  // NOTE: responsible for setting up/tearing down the scroll handler
  useEffect(() => {
    const player = getPlayer();
    const handleScroll = debounce(() => {
      if (!player || playerState === PlayerState.READY) return;

      if (
        !isSrcHls(src) &&
        !isTouchDevice() &&
        isElementInViewport(player) &&
        playerState === PlayerState.PAUSED
      ) {
        player.play();
      } else if (!isElementInViewport(player) && playerState === PlayerState.PLAYING) {
        player.pause();
        if (!player.muted) {
          player.muted = true;
          dispatch({ type: ActionType.TOGGLE_MUTE });
        }
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [playerState, getPlayer, src]);

  // NOTE: responsible for fading out controls upon playerState transitions
  useEffect(() => {
    if (playerState === PlayerState.PLAYING) {
      clearTimeout(fadeOutTimerId.current);
      fadeOutTimerId.current = fadeOut(1000);
    } else if (playerState === PlayerState.PAUSED) {
      clearTimeout(fadeOutTimerId.current);
    }

    return () => clearTimeout(fadeOutTimerId.current);
  }, [playerState]);

  const handleMuteUnmute = useCallback(() => {
    const player = getPlayer();

    if (player instanceof HTMLVideoElement) {
      player.muted = !player.muted;
      player.muted ? onSoundOff({ type: 'sound_off' }) : onSoundOn({ type: 'sound_on' });
      dispatch({ type: ActionType.TOGGLE_MUTE });
    }
  }, [getPlayer, onSoundOff, onSoundOn]);

  const handlePlayPause = useCallback(async () => {
    const player = getPlayer();
    const hls = getHls();

    // NOTE: We don't support pause for non-streaming media
    if (!isSrcHls(src) && playerState === PlayerState.PLAYING) return;

    if (player instanceof HTMLVideoElement && player.paused) {
      // we only want to load the hls manifest prior to the initial play
      if (hls && playerState === PlayerState.READY) {
        hls.loadSource(src);
      }

      player.play();
    } else if (player instanceof HTMLVideoElement) {
      player.pause();
    }
  }, [getHls, getPlayer, playerState, src]);

  // NOTE: resposible for adding support for various keyboard shortcuts
  // - ': toggle playback speed
  // - k: toggle play/pause
  // - m: toggle mute/unmute
  useEffect(() => {
    const keyCodeMap = {
      [KeyCode.QUOTE]: togglePlaybackSpeed,
      [KeyCode.K]: handlePlayPause,
      [KeyCode.M]: handleMuteUnmute,
    };

    function togglePlaybackSpeed() {
      const player = getPlayer();
      if (!player) return;

      const currPlaybackRate = player.playbackRate;

      if (currPlaybackRate === 1.0) {
        player.playbackRate = 2.0;
      } else if (currPlaybackRate === 2.0) {
        player.playbackRate = 1.0;
      }
    }

    function handleKeyDown(evt: KeyboardEvent) {
      if (mediaId !== selectedMediaId) return;

      try {
        const keyCode = evt.code as KeyCode.QUOTE | KeyCode.M | KeyCode.K;

        keyCodeMap[keyCode]();
      } catch (err) {
        return;
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [getPlayer, playerState, mediaId, selectedMediaId, handleMuteUnmute, handlePlayPause]);

  // TODO: investigate updating handler to support mouse enter/exit events
  function handleContainerClick() {
    dispatch({ type: ActionType.SHOW_CONTROLS });

    if (playerState === PlayerState.PLAYING) {
      clearTimeout(fadeOutTimerId.current);
      fadeOutTimerId.current = fadeOut(2000);
    }
  }

  function handleExpandClick() {
    const player = getPlayer();

    player?.pause();
    if (player) player.muted = true;
    dispatch({ type: ActionType.TOGGLE_MUTE });
    onExpand?.();
  }

  function fadeOut(timeout: number) {
    return window.setTimeout(() => dispatch({ type: ActionType.HIDE_CONTROLS }), timeout);
  }

  function shouldShowSpinner() {
    return playerState === PlayerState.LOADING || playerState === PlayerState.WAITING;
  }

  return (
    <Container
      onClick={handleContainerClick}
      onMouseEnter={() => dispatch({ type: ActionType.SHOW_CONTROLS })}
      onMouseLeave={() => dispatch({ type: ActionType.HIDE_CONTROLS })}
    >
      {shouldShowSpinner() ? (
        <VideoControlsSpinner />
      ) : (
        <VideoControlsPlayPause
          shouldHide={shouldHideControls}
          isPlaying={playerState === PlayerState.PLAYING}
          onClick={handlePlayPause}
        />
      )}

      <MiniControlsContainer shouldHide={shouldHideControls}>
        <VideoControlsMuteUnMute hasAudio={hasAudio} isMuted={isMuted} onClick={handleMuteUnmute} />

        {/* TODO: implement progress and seek functionality to aid reporting */}
        <ProgressBarPlaceholder />

        <DurationExpandContainer hasExpand={mode === VideoControlsMode.Default}>
          <TimeRemaining>{timeRemaining || toMinutes(duration)}</TimeRemaining>

          {mode === VideoControlsMode.Default && (
            <ExpandButton onClick={handleExpandClick}>
              <IconVideoControlsExpand />
            </ExpandButton>
          )}
        </DurationExpandContainer>
      </MiniControlsContainer>
    </Container>
  );
}

export default VideoControls;
